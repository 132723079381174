import React from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { 
	faPenToSquare,
	faTrashCan,
	faFilter,
	faAngleDown,
	faAngleUp,
	faSignOut,
	faCircleInfo,
	faEyeSlash,
	faVideo,
	faFile,
	faDownload,
	faNoteSticky,
	faTasks,
	faHospitalUser,
	faUserDoctor,
	faSquareVirus,
	faSmoking,
	faMartiniGlass,
	faPersonRunning,
	faSquareCheck,
	faSuitcaseMedical,
	faCalendarAlt,
	faFileSignature,
	faFileExcel,
	faFileCircleCheck,
	faPlus,
	faPlusCircle,
	faMinusCircle
} from '@fortawesome/free-solid-svg-icons'

import { 
	faEye,
	faFileLines
} from '@fortawesome/free-regular-svg-icons'

import { 
	faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'

import styles from './Icon.module.css'

const Icon = ({ icon, margin = 0, color = "7180961", pointer = false, info = null, size = null}) => {
	const icons = {
		view: faEye,
		edit: faPenToSquare,
		delete: faTrashCan,
		filter: faFilter,
		expand: faAngleDown,
		shrink: faAngleUp,
		logout: faSignOut,
		info: faCircleInfo,
		eyeClose: faEyeSlash,
		tele: faVideo,
		document: faFile,
		documents: faFileLines,
		download: faDownload,
		note: faNoteSticky,
		task: faTasks,
		hospitalization: faHospitalUser,
		medical_appointment: faUserDoctor,
		disease: faSquareVirus,
		smoking: faSmoking,
		alcohol_consumption: faMartiniGlass,
		physical_activity: faPersonRunning,
		medical_case: faSuitcaseMedical,
		calendar: faCalendarAlt,
		whatsapp: faWhatsapp,
		signature: faFileSignature,
		signatureDeclined: faFileExcel,
		documentsReceived: faFileCircleCheck,
		plus: faPlusCircle,
		minus: faMinusCircle,
	};

	return (
		<div className={styles.icon}
			 style={{marginRight: margin + 'px', cursor: pointer && 'pointer'}}
			 title={info || null} color={color}>
			<FontAwesomeIcon icon={icons[icon]} size={size}  color={color}/>
		</div>
	)
}

export default Icon
