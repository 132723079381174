import { validateCompetence } from "variables/regex";
import { validateDate } from "variables/regex";
import * as yup from "yup";

export const outgoingSchema = yup.object().shape({
  area_id: yup.string(),
  payed_at: yup.string().when({
    is: (value) => value != null && value !== undefined && value !== "",
    then: yup.string().matches(validateDate, "Insira uma data válida"),
    otherwise: yup.string(),
  }),
  competence: yup.string().when({
    is: (value) => value != null && value !== undefined && value !== "",
    then: yup.string().matches(validateCompetence, "Insira uma data válida"),
    otherwise: yup.string(),
  }),
});
