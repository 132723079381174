import { gql } from "@apollo/client";

export const GET_OUTGOINGS = gql`
  query getOutgoings {
    outgoings {
      id
      payed_at
      competence
      value
      area {
        name
      }
    }
  }
`;

export const GET_OUTGOINGS_PAGINATE = gql`
  query getOutgoings(
    $first: Int! = 30
    $page: Int! = 1
    $area_id: String
    $competence: String
  ) {
    paginateOutgoings(
      first: $first
      page: $page
      area_id: $area_id
      competence: $competence
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        payed_at
        competence
        value
        area {
          name
        }
      }
    }
  }
`;

export const GET_OUTGOING = gql`
  query getOutgoing($id: ID!) {
    outgoing(id: $id) {
      id
      area_id
      value
      payed_at
      competence
      description
      area {
        name
      }
      tags {
        id
        name
        color
      }
    }
  }
`;

export const CREATE_OUTGOING = gql`
  mutation createOutgoing($input: CreateOutgoing) {
    createOutgoing(input: $input) {
      id
      payed_at
      description
    }
  }
`;

export const UPDATE_OUTGOING = gql`
  mutation updateOutgoing($input: UpdateOutgoing) {
    updateOutgoing(input: $input) {
      id
    }
  }
`;

export const DELETE_OUTGOING = gql`
  mutation deleteOutgoing($id: ID!) {
    deleteOutgoing(id: $id) {
      id
    }
  }
`;
