export const mod = (n, m) => {
  if (n < 0) {
    return mod(n + m, m);
  } else {
    return n % m;
  }
};

export const aditionalZero = (num) => {
  if (num < 10) return "0" + num;

  return num;
};
