import { gql } from "@apollo/client";

export const ME = gql`
  query me {
    me {
      id
      email
    }
  }
`;

export const GET_USERS = gql`
  query getUsers {
    users {
      id
      name
      email
    }
  }
`;

export const GET_USERS_PAGINATE = gql`
  query getUsers($first: Int! = 30, $page: Int! = 1) {
    paginateUsers(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        name
        email
        created_at
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      name
      email
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($input: CreateUser) {
    createUser(input: $input) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUser) {
    updateUser(input: $input) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;
