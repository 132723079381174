import React, { useEffect, useState } from "react";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Select,
  Skeleton,
  useColorModeValue,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import MultiSelect from "components/MultiSelect/MultiSelect";
import { useAllAreas } from "hooks/areas";
import InputMask from "react-input-mask";

const CollaboratorForm = ({ defaultValues = {} }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const wPaymentMethod = watch(
    "payment_method",
    defaultValues?.payment_method || ""
  );

  const { data: { areas } = {}, loading } = useAllAreas();

  const inputHover = useColorModeValue("governance.800", "governance.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.name}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Nome
          </FormLabel>
          <Input
            {...register("name")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.name}
          />
          {errors.name && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.cpf}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            CPF
          </FormLabel>
          <Input
            {...register("cpf")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.cpf}
            as={InputMask}
            mask="999.999.999-99"
          />
          {errors.cpf && (
            <FormErrorMessage>{errors.cpf.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
        gap="24px"
        mt="24px"
      >
        <FormControl isInvalid={errors.email}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            E-mail
          </FormLabel>
          <Input
            {...register("email")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.email}
          />
          {errors.email && (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={errors.phone}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Telefone
          </FormLabel>
          <Input
            {...register("phone")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.phone}
          />
          {errors.phone && (
            <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
        gap="24px"
        mt="24px"
      >
        <FormControl isInvalid={errors.purpose}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Função
          </FormLabel>
          <Input
            {...register("purpose")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.purpose}
          />
          {errors.purpose && (
            <FormErrorMessage>{errors.purpose.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={errors.type}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Tipo
          </FormLabel>
          <Select
            {...register("type")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.type}
          >
            <option value="internal">Interno</option>
            <option value="external">Externo</option>
          </Select>
          {errors.type && (
            <FormErrorMessage>{errors.type.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
        gap="24px"
        mt="24px"
      >
        <FormControl isInvalid={errors.journey}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Jornada
          </FormLabel>
          <Select
            {...register("journey")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.journey}
          >
            <option value="continuous">Contínua</option>
            <option value="intermittent">Intermitente</option>
          </Select>
          {errors.journey && (
            <FormErrorMessage>{errors.journey.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={errors.payment_method}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Tipo de pagamento
          </FormLabel>
          <Select
            {...register("payment_method")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.payment_method}
          >
            <option value="account">Conta</option>
            <option value="pix">Pix</option>
          </Select>
          {errors.payment_method && (
            <FormErrorMessage>{errors.payment_method.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      {wPaymentMethod === "pix" ? (
        <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
          <FormControl isInvalid={errors.pix_key}>
            <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
              Chave Pix
            </FormLabel>
            <Input
              {...register("pix_key")}
              focusBorderColor={inputHover}
              borderRadius="8px"
              fontSize="md"
              defaultValue={defaultValues?.pix_key}
            />
            {errors.pix_key && (
              <FormErrorMessage>{errors.pix_key.message}</FormErrorMessage>
            )}
          </FormControl>
        </Grid>
      ) : (
        <>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
            gap="24px"
            mt="24px"
          >
            <FormControl isInvalid={errors.bank}>
              <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                Banco
              </FormLabel>
              <Input
                {...register("bank")}
                focusBorderColor={inputHover}
                borderRadius="8px"
                fontSize="md"
                defaultValue={defaultValues?.bank}
              />
              {errors.bank && (
                <FormErrorMessage>{errors.bank.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={errors.agency}>
              <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                Agência
              </FormLabel>
              <Input
                {...register("agency")}
                focusBorderColor={inputHover}
                borderRadius="8px"
                fontSize="md"
                defaultValue={defaultValues?.agency}
              />
              {errors.agency && (
                <FormErrorMessage>{errors.agency.message}</FormErrorMessage>
              )}
            </FormControl>
          </Grid>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
            gap="24px"
            mt="24px"
          >
            <FormControl isInvalid={errors.account_number}>
              <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                Número da conta
              </FormLabel>
              <Input
                {...register("account_number")}
                focusBorderColor={inputHover}
                borderRadius="8px"
                fontSize="md"
                defaultValue={defaultValues?.account_number}
              />
              {errors.account_number && (
                <FormErrorMessage>
                  {errors.account_number.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </Grid>
        </>
      )}

      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
        gap="24px"
        mt="24px"
      >
        <FormControl>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Áreas de Atuação
          </FormLabel>
          {loading ? (
            <Skeleton height="20px" />
          ) : (
            <MultiSelect
              fieldName={"areas"}
              options={areas}
              defaultSelection={defaultValues?.areas}
            />
          )}
        </FormControl>
      </Grid>
    </>
  );
};

export default CollaboratorForm;
