import React from "react";

import { NavLink, useHistory, useParams } from "react-router-dom";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactSchema } from "./yupSchema";

import {
  Button,
  Flex,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ContactForm from "./ContactForm";
import { useUpdateContact } from "hooks/contacts";
import { useContact } from "hooks/contacts";

const EditContact = ({ id, isOpen, closeModal }) => {
  const { data: { contact } = {}, loading } = useContact(id);

  console.log(contact);

  const { id: clientId } = useParams();

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(contactSchema),
  });
  const toast = useToast();

  const { reset, handleSubmit } = methods;

  const [updateContact, { loading: loadingUpdate, error }] = useUpdateContact({
    onSuccess: (data) => {
      reset();
      toast({
        title: "Usuário atualizado com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      }),
        closeModal();
    },
    onError: (err) => {
      console.log(err);
      toast({
        title: "Ocorreu um erro",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleDataBeforeSubmit = (data) => {
    data.cnpj = data?.cnpj?.replace(/[^0-9]/g, "");
    return data;
  };

  const onSubmit = (data) => {
    data = handleDataBeforeSubmit(data);
    const variables = {
      input: {
        id: id,
        ...data,
      },
    };

    updateContact({ variables: variables });
  };

  return (
    <Modal
      isCentered
      onClose={() => closeModal()}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <FormProvider {...methods}>
        <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Editar Contato</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="column" w="100%">
                {!loading && <ContactForm defaultValues={contact} />}
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button
                mr={3}
                variant="ghost"
                onClick={() => {
                  reset();
                  closeModal();
                }}
              >
                Cancelar
              </Button>
              <Button
                isLoading={loading}
                type="submit"
                variant="no-hover"
                bg="governance.700"
                alignSelf="flex-end"
                w="100px"
                h="35px"
              >
                <Text fontSize="xs" color="#fff" fontWeight="bold">
                  Atualizar
                </Text>
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditContact;
