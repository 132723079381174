import { gql } from '@apollo/client';

export const GET_TAGS =  gql`
    query getTags {
        tags {
            id
            name
            color
        }
    }
`

export const GET_TAG = gql`
    query getTag($id: ID!) {
        tag(id: $id) {
            id
            name
            color
        }
    }
`

export const CREATE_TAG = gql`
    mutation createTag($input: CreateTag) {
        createTag(input: $input) {
            id
            name
            color
        }
    }
`

export const DELETE_TAG = gql`
    mutation deleteTag($id: ID!) {
        deleteTag(id: $id) {
            id
        }
    }
`
