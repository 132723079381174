import React from "react";
import { createContext, useContext, useReducer } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  GET_OUTGOINGS,
  GET_OUTGOINGS_PAGINATE,
  GET_OUTGOING,
  CREATE_OUTGOING,
  UPDATE_OUTGOING,
  DELETE_OUTGOING,
} from "graphql/outgoings";
import { ACTION_TYPES, reducers } from "./reducers";

const OutgoingsStateContext = createContext();
const OutgoingDispatchContext = createContext();

const OutgoingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {
    rows: [],
    first: 15,
    page: 1,
  });

  const { data, loading, error } = useQuery(GET_OUTGOINGS_PAGINATE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.PAGINATE, data: data.paginateOutgoings });
    },
  });

  return (
    <OutgoingsStateContext.Provider value={{ data, loading, error, ...state }}>
      <OutgoingDispatchContext.Provider value={dispatch}>
        {children}
      </OutgoingDispatchContext.Provider>
    </OutgoingsStateContext.Provider>
  );
};

const useOutgoings = () => {
  const context = useContext(OutgoingsStateContext);

  if (!context)
    throw new Error("useOutgoings must be used within an OutgoingsProvider");

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(OutgoingDispatchContext);

  if (dispatch === undefined)
    throw new Error("useDispatch must be used within a OutgoingsProvider");

  return dispatch;
};

const usePaginateOutgoings = () => {
  const dispatch = useDispatch();

  return useLazyQuery(GET_OUTGOINGS_PAGINATE, {
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.PAGINATE, data: data.paginateOutgoings });
    },
  });
};

const useAllOutgoings = () => {
  const dispatch = useDispatch();

  return useQuery(GET_OUTGOINGS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.ALL, data: data.areas });
    },
  });
};

const useCreateOutgoing = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(CREATE_OUTGOING, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.ADD, data: data.createOutgoing });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

const useOutgoing = (id) => {
  const dispatch = useContext(OutgoingDispatchContext);

  return useQuery(
    GET_OUTGOING,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    },
    {
      onCompleted: (data) => {
        dispatch({ type: ACTION_TYPES.VIEW, data: data.area });
      },
    }
  );
};

const useUpdateOutgoing = (options = {}) => {
  const dispatch = useContext(OutgoingDispatchContext);
  const { onSuccess, onError } = options;

  return useMutation(UPDATE_OUTGOING, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateOutgoing });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
    refetchQueries: [
      {
        query: GET_OUTGOINGS_PAGINATE,
      },
    ],
  });
};

const useDeleteOutgoing = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(DELETE_OUTGOING, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.REMOVE, id: data.deleteOutgoing?.id });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

export {
  OutgoingsProvider,
  useOutgoings,
  useCreateOutgoing,
  usePaginateOutgoings,
  useOutgoing,
  useUpdateOutgoing,
  useDeleteOutgoing,
  useAllOutgoings,
};
