import React, { useEffect, useState } from "react";

import {
  Center,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Input,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";

import logo from "assets/img/logo_healthid.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useLogin } from "hooks/auth";
import { useAuth } from "hooks/auth";

export const Login = () => {
  const { register, handleSubmit } = useForm();

  const history = useHistory();

  const [seePassword, setSeePassword] = useState(false);
  const [submitData, setSubmit] = useState(null);
  const [loginError, setError] = useState(false);

  const { user: isAuthenticated, isLoading } = useAuth();
  const { data: successfullLogin, error } = useLogin(submitData, {
    enabled: !!submitData,
  });

  useEffect(() => {
    if (isAuthenticated) history.push("/sistema");
  }, [isAuthenticated]);

  const onSubmit = (data) => {
    setError(false);
    setSubmit(data);
  };

  useEffect(() => {
    setSubmit(null);
  }, [submitData]);

  useEffect(() => {
    if (error) setError(true);
  }, [error]);

  return (
    !isLoading && (
      <Center
        h="100vh"
        backgroundColor={"governance.600"}
        align="center"
        justify="center"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack
            spacing="24px"
            backgroundColor="white"
            borderRadius="24px"
            padding="5em"
          >
            <Image src={logo} maxW="150px" m="2em 1em" />
            <FormControl>
              <FormLabel>E-mail</FormLabel>
              <Input {...register("email")} />
            </FormControl>
            <FormControl>
              <FormLabel>Senha</FormLabel>
              <Flex>
                <Input
                  borderRightRadius={0}
                  type={seePassword ? "text" : "password"}
                  {...register("password")}
                />
                <IconButton
                  borderLeftRadius={0}
                  aria-label="Mostrar"
                  onClick={() => setSeePassword(!seePassword)}
                  icon={seePassword ? <FaEyeSlash /> : <FaEye />}
                />
              </Flex>
            </FormControl>
            {loginError && (
              <Text color="red.500">Não foi possível realizar login</Text>
            )}
            <FormControl>
              <Input
                bg="governance.700"
                color="white"
                _hover={{ cursor: "pointer", bg: "governance.800" }}
                type="submit"
                value="Login"
              />
            </FormControl>
          </VStack>
        </form>
      </Center>
    )
  );
};

export default Login;
