import React from "react";
import { createContext, useContext, useReducer } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  GET_CONTACTS,
  GET_CONTACTS_PAGINATE,
  GET_CONTACT,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
} from "graphql/contacts";
import { ACTION_TYPES, reducers } from "./reducers";

const ContactsStateContext = createContext();
const ContactDispatchContext = createContext();

const ContactsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {
    data: [],
    loading: false,
    error: false,
    rows: [],
    first: 5,
    page: 1,
  });

  return (
    <ContactsStateContext.Provider value={{ ...state }}>
      <ContactDispatchContext.Provider value={dispatch}>
        {children}
      </ContactDispatchContext.Provider>
    </ContactsStateContext.Provider>
  );
};

const useContacts = () => {
  const context = useContext(ContactsStateContext);

  if (!context)
    throw new Error("useContacts must be used within an ContactsProvider");

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(ContactDispatchContext);

  if (dispatch === undefined)
    throw new Error("useDispatch must be used within a ContactsProvider");

  return dispatch;
};

const usePaginateContacts = () => {
  const dispatch = useDispatch();

  return useLazyQuery(GET_CONTACTS_PAGINATE, {
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.PAGINATE, data: data.paginateContacts });
    },
  });
};

const useAllContacts = () => {
  const dispatch = useDispatch();

  return useQuery(GET_CONTACTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.ALL, data: data.contacts });
    },
  });
};

const useCreateContact = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(CREATE_CONTACT, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.ADD, data: data.createContact });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

const useContact = (id) => {
  const dispatch = useContext(ContactDispatchContext);

  return useQuery(
    GET_CONTACT,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    },
    {
      onCompleted: (data) => {
        dispatch({ type: ACTION_TYPES.VIEW, data: data.area });
      },
    }
  );
};

const useUpdateContact = (options = {}) => {
  const dispatch = useContext(ContactDispatchContext);
  const { onSuccess, onError } = options;

  return useMutation(UPDATE_CONTACT, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateContact });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
    refetchQueries: [
      {
        query: GET_CONTACTS_PAGINATE,
      },
    ],
  });
};

const useDeleteContact = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(DELETE_CONTACT, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.REMOVE, id: data.deleteContact?.id });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

export {
  ContactsProvider,
  useContacts,
  useCreateContact,
  usePaginateContacts,
  useContact,
  useUpdateContact,
  useDeleteContact,
  useAllContacts,
};
