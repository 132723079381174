import UsersProvider from "providers/usersProvider";
import Users from "views/Users/";
import CreateUser from "views/Users/CreateUser";

import CollaboratorsProvider from "providers/collaboratorsProvider";
import Collaborators from "views/Collaborators";
import CreateCollaborator from "views/Collaborators/CreateCollaborator";
import ViewCollaborator from "views/Collaborators/ViewCollaborator";
import EditCollaborator from "views/Collaborators/EditCollaborator";

import AreasProvider from "providers/areasProvider";
import Areas from "views/Areas";
import CreateArea from "views/Areas/CreateArea";
import ViewArea from "views/Areas/ViewArea";
import EditArea from "views/Areas/EditArea";

import ClientsProvider from "providers/clientsProvider";
import Clients from "views/Clients";
import CreateClient from "views/Clients/CreateClient";
import ViewClient from "views/Clients/ViewClient";
import EditClient from "views/Clients/EditClient";

import OutgoingsProvider from "providers/outgoingsProvider";
import Outgoings from "views/Outgoings";
import CreateOutgoing from "views/Outgoings/CreateOutgoing";
import ViewOutgoing from "views/Outgoings/ViewOutgoing";
import EditOutgoing from "views/Outgoings/EditOutgoing";

import IncomingsProvider from "providers/incomingsProvider";
import Incomings from "views/Incomings";
import CreateIncoming from "views/Incomings/CreateIncoming";
import ViewIncoming from "views/Incomings/ViewIncoming";
import EditIncoming from "views/Incomings/EditIncoming";

const routes = [
  // USUARIOS
  {
    name: "usuarios",
    path: "/administracao/usuarios",
    component: Users,
    provider: UsersProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "usuarios",
    path: "/administracao/usuarios/criar",
    component: CreateUser,
    provider: UsersProvider,
    layout: "/sistema",
    roles: [],
  },
  // Colaboradores
  {
    name: "colaboradores",
    path: "/administracao/colaboradores",
    component: Collaborators,
    provider: CollaboratorsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "criar colaborador",
    path: "/administracao/colaboradores/criar",
    component: CreateCollaborator,
    provider: CollaboratorsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "visualizar colaborador",
    path: "/administracao/colaboradores/:id",
    component: ViewCollaborator,
    provider: CollaboratorsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "editar colaborador",
    path: "/administracao/colaboradores/:id/editar",
    component: EditCollaborator,
    provider: CollaboratorsProvider,
    layout: "/sistema",
    roles: [],
  },
  // Areas
  {
    name: "areas",
    path: "/administracao/areas",
    component: Areas,
    provider: AreasProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "criar area",
    path: "/administracao/areas/criar",
    component: CreateArea,
    provider: AreasProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "visualizar area",
    path: "/administracao/areas/:id",
    component: ViewArea,
    provider: AreasProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "editar area",
    path: "/administracao/areas/:id/editar",
    component: EditArea,
    provider: AreasProvider,
    layout: "/sistema",
    roles: [],
  },
  // Clientes
  {
    name: "clientes",
    path: "/administracao/clientes",
    component: Clients,
    provider: ClientsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "criar cliente",
    path: "/administracao/clientes/criar",
    component: CreateClient,
    provider: ClientsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "visualizar cliente",
    path: "/administracao/clientes/:id",
    component: ViewClient,
    provider: ClientsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "editar cliente",
    path: "/administracao/clientes/:id/editar",
    component: EditClient,
    provider: ClientsProvider,
    layout: "/sistema",
    roles: [],
  },
  // Gastos
  {
    name: "saídas",
    path: "/financeiro/saidas",
    component: Outgoings,
    provider: OutgoingsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "criar saída",
    path: "/financeiro/saidas/criar",
    component: CreateOutgoing,
    provider: OutgoingsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "visualizar saída",
    path: "/financeiro/saidas/:id",
    component: ViewOutgoing,
    provider: OutgoingsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "editar saída",
    path: "/financeiro/saidas/:id/editar",
    component: EditOutgoing,
    provider: IncomingsProvider,
    layout: "/sistema",
    roles: [],
  },
  // Ganhos
  {
    name: "entradas",
    path: "/financeiro/entradas",
    component: Incomings,
    provider: IncomingsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "criar saída",
    path: "/financeiro/entradas/criar",
    component: CreateIncoming,
    provider: IncomingsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "visualizar saída",
    path: "/financeiro/entradas/:id",
    component: ViewIncoming,
    provider: IncomingsProvider,
    layout: "/sistema",
    roles: [],
  },
  {
    name: "editar saída",
    path: "/financeiro/entradas/:id/editar",
    component: EditIncoming,
    provider: IncomingsProvider,
    layout: "/sistema",
    roles: [],
  },
];

export default routes;
