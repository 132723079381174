import React, { useState, useEffect } from "react";

import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  VStack,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const UserForm = ({ defaultValues = {} }) => {
  const {
    register,
    setValue,
    formState: { errors, isValid },
  } = useFormContext();

  const [seePassword, setSeePassword] = useState(false);

  return (
    <VStack spacing="24px" backgroundColor="white" borderRadius="24px">
      <FormControl isInvalid={errors?.name}>
        <FormLabel>Nome</FormLabel>
        <Input {...register("name")} />
        {errors?.name && (
          <FormErrorMessage>{errors.name.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={errors?.email}>
        <FormLabel>E-mail</FormLabel>
        <Input {...register("email")} />
        {errors?.email && (
          <FormErrorMessage>{errors.email.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={errors?.password}>
        <FormLabel>Senha</FormLabel>
        <Flex>
          <Input
            borderRightRadius={0}
            type={seePassword ? "text" : "password"}
            {...register("password")}
          />
          <IconButton
            borderLeftRadius={0}
            aria-label="Mostrar"
            onClick={() => setSeePassword(!seePassword)}
            icon={seePassword ? <FaEyeSlash /> : <FaEye />}
          />
        </Flex>
        {errors?.password && (
          <FormErrorMessage>{errors.password.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={errors?.password_confirmation}>
        <FormLabel>Confirme a senha</FormLabel>
        <Flex>
          <Input
            borderRightRadius={0}
            type={seePassword ? "text" : "password"}
            {...register("password_confirmation")}
          />
          <IconButton
            borderLeftRadius={0}
            aria-label="Mostrar"
            onClick={() => setSeePassword(!seePassword)}
            icon={seePassword ? <FaEyeSlash /> : <FaEye />}
          />
        </Flex>
        {errors?.password_confirmation && (
          <FormErrorMessage>
            {errors.password_confirmation.message}
          </FormErrorMessage>
        )}
      </FormControl>
    </VStack>
  );
};

export default UserForm;
