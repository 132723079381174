import React from "react";
import { createContext, useContext, useReducer } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  GET_INCOMINGS,
  GET_INCOMINGS_PAGINATE,
  GET_INCOMING,
  CREATE_INCOMING,
  UPDATE_INCOMING,
  DELETE_INCOMING,
  GET_IMPORT,
} from "graphql/incomings";
import { ACTION_TYPES, reducers } from "./reducers";
import { useHistory } from "react-router-dom";

const IncomingsStateContext = createContext();
const IncomingsDispatchContext = createContext();

const IncomingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {
    rows: [],
    first: 15,
    page: 1,
  });

  const { data, loading, error } = useQuery(GET_INCOMINGS_PAGINATE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.PAGINATE, data: data.paginateIncomings });
    },
  });

  return (
    <IncomingsStateContext.Provider value={{ data, loading, error, ...state }}>
      <IncomingsDispatchContext.Provider value={dispatch}>
        {children}
      </IncomingsDispatchContext.Provider>
    </IncomingsStateContext.Provider>
  );
};

const useIncomings = () => {
  const context = useContext(IncomingsStateContext);

  if (!context)
    throw new Error("useIncomings must be used within an IncomingsProvider");

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(IncomingsDispatchContext);

  if (dispatch === undefined)
    throw new Error("useDispatch must be used within a IncomingsProvider");

  return dispatch;
};

const usePaginateIncomings = () => {
  const dispatch = useDispatch();

  return useLazyQuery(GET_INCOMINGS_PAGINATE, {
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.PAGINATE, data: data.paginateIncomings });
    },
  });
};

const useAllIncomings = () => {
  const dispatch = useDispatch();

  return useQuery(GET_INCOMINGS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.ALL, data: data.areas });
    },
  });
};

const useCreateIncoming = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(CREATE_INCOMING, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.ADD, data: data.createIncoming });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

const useIncoming = (id) => {
  const dispatch = useContext(IncomingsDispatchContext);

  return useQuery(
    GET_INCOMING,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    },
    {
      onCompleted: (data) => {
        dispatch({ type: ACTION_TYPES.VIEW, data: data.incoming });
      },
    }
  );
};

const useUpdateIncoming = (options = {}) => {
  const dispatch = useContext(IncomingsDispatchContext);
  const { onSuccess, onError } = options;

  return useMutation(UPDATE_INCOMING, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateIncoming });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
    refetchQueries: [
      {
        query: GET_INCOMINGS_PAGINATE,
      },
    ],
  });
};

const useDeleteIncoming = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(DELETE_INCOMING, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.REMOVE, id: data.deleteIncoming?.id });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

const useGetImport = (options = {}) => {
  const { onError } = options;
  const dispatch = useContext(IncomingsDispatchContext);

  return useLazyQuery(GET_IMPORT, {
    onCompleted: (data) => {
      window.open(data?.getImport?.url, "_blank");
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

export {
  IncomingsProvider,
  useIncomings,
  useCreateIncoming,
  usePaginateIncomings,
  useIncoming,
  useUpdateIncoming,
  useDeleteIncoming,
  useAllIncomings,
  useGetImport,
};
