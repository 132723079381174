import { useState } from "react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import { Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";

import ProductsTable from "./Products/ProductsTable";

export default ({ client, loading }) => {
  const [createModal, setCreateModal] = useState(false);

  const buttonBg = useColorModeValue("governance.700", "governance.700");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card w={{ md: "100%", lg: "50%" }}>
      <CardHeader mb="40px">
        <Flex direction="row" w="100%" justify="space-between">
          <Text color={textColor} fontSize="lg" fontWeight="bold" mb="3px">
            Produtos
          </Text>
          <Flex>
            <Button
              onClick={() => setCreateModal(true)}
              backgroundColor={buttonBg}
              color="white"
              _hover={{ bg: "governance.800" }}
              mr="12px"
              padding="0 24px"
            >
              Adicionar
            </Button>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction="column" w="100%" maxH="480px">
          {!loading && (
            <ProductsTable
              clientId={client?.id}
              createModal={createModal}
              setCreateModal={setCreateModal}
            />
          )}
        </Flex>
      </CardBody>
    </Card>
  );
};
