import { ClientsProvider } from "hooks/clients";
import { IncomingsProvider as Provider } from "hooks/incomings/index";
import { ProductsProvider } from "hooks/products";
import { ApolloQueryProvider } from "services/apollo";

const IncomingsProvider = ({ children }) => {
  return (
    <ApolloQueryProvider>
      <ClientsProvider>
        <ProductsProvider>
          <Provider>{children}</Provider>
        </ProductsProvider>
      </ClientsProvider>
    </ApolloQueryProvider>
  );
};

export default IncomingsProvider;
