import { useUsers } from "hooks/users";

import dateFormat from "functions/dateFormat";
import { usePaginateUsers } from "hooks/users";
import AsyncTable from "components/Tables/AsyncTable";
import { useState } from "react";
import DeleteUser from "./DeleteUser";

const columns = [
  {
    Header: "Nome",
    accessor: "name",
    isLink: true,
  },
  {
    Header: "E-mail",
    accessor: "email",
  },
  {
    Header: "Cadastrado em",
    accessor: "created_at",
    Cell: (props) => <div>{dateFormat(props.value)}</div>,
  },
];

const UsersTable = () => {
  const { loading, rows, total, perPage, currentPage } = useUsers();
  const [paginateUsers, { loading: reloading }] = usePaginateUsers();
  const [deleteModal, setDeleteModal] = useState(false);
  const [user, setUser] = useState({});

  const fetchData = ({ pageSize, pageIndex, search }) => {
    if (pageSize != perPage) pageIndex = 1;
    paginateUsers({
      variables: {
        first: pageSize || 30,
        page: pageIndex || 1,
        search: search || "",
      },
    });
  };

  const handleDelete = (id) => {
    setUser({ id: id });
    setDeleteModal(true);
  };

  return (
    <>
      <DeleteUser
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(false)}
        user={user}
      />
      <AsyncTable
        isLoading={loading || reloading}
        fetchData={fetchData}
        data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
        columns={columns}
        total={total}
        asyncPage={currentPage}
        searchByName
        baseRoute="usuarios"
        removeAction={(id) => handleDelete(id)}
        noView
        noEdit
      />
    </>
  );
};

export default UsersTable;
