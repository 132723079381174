import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  List,
  ListIcon,
  ListItem,
  Skeleton,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useArea } from "hooks/areas";
import { CheckCircleIcon } from "@chakra-ui/icons";

const ViewArea = () => {
  const { id } = useParams();
  const { data: { area } = {}, loading } = useArea(id);

  const buttonBg = useColorModeValue("governance.700", "governance.700");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <Card w={{ md: "100%", lg: "50%" }}>
        <CardHeader mb="40px">
          <Flex direction="row" w="100%" justify="space-between">
            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="3px">
              Área
            </Text>
            <Flex>
              <NavLink to={`/sistema/administracao/areas/${id}/editar`}>
                <Button
                  backgroundColor={buttonBg}
                  color="white"
                  _hover={{ bg: "governance.800" }}
                  mr="12px"
                  padding="0 24px"
                >
                  Editar
                </Button>
              </NavLink>
              <NavLink to="/sistema/administracao/areas">
                <Button>Voltar</Button>
              </NavLink>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <VStack spacing="24px">
              <FormControl>
                <FormLabel color="gray.600" fontSize="xs">
                  Nome
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Flex align="center">
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mr="12px"
                    >
                      {area?.name || null}
                    </Text>
                  </Flex>
                </Skeleton>
              </FormControl>

              {area?.collaborators?.length > 0 && (
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Colaboradores
                  </FormLabel>
                  <Flex align="center">
                    <List ml="6px" spacing={3}>
                      {area?.collaborators?.map((collaborator) => (
                        <ListItem fontWeight="bold">
                          <ListIcon
                            color="governance.700"
                            as={CheckCircleIcon}
                          />
                          <NavLink
                            to={`/sistema/administracao/colaboradores/${collaborator?.id}`}
                          >
                            {collaborator?.name}
                          </NavLink>
                        </ListItem>
                      ))}
                    </List>
                  </Flex>
                </FormControl>
              )}
            </VStack>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default ViewArea;
