import React from "react";
import { createContext, useContext, useReducer } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { GET_TAGS, GET_TAG, CREATE_TAG, DELETE_TAG } from "graphql/tags";
import { ACTION_TYPES, reducers } from "./reducers";

const TagsStateContext = createContext();
const TagDispatchContext = createContext();

const TagsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {});
  const { data, loading, error } = useQuery(GET_TAGS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.ALL, data: data.tags });
    },
  });

  return (
    <TagsStateContext.Provider value={{ data, loading, error, ...state }}>
      <TagDispatchContext.Provider value={dispatch}>
        {children}
      </TagDispatchContext.Provider>
    </TagsStateContext.Provider>
  );
};

const useTags = () => {
  const context = useContext(TagsStateContext);

  if (!context) throw new Error("useTags must be used within an TagsProvider");

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(TagDispatchContext);

  if (dispatch === undefined)
    throw new Error("useDispatch must be used within a TagsProvider");

  return dispatch;
};

const useAllTags = () => {
  const dispatch = useDispatch();

  return useLazyQuery(GET_TAGS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.ALL, data: data.tags });
    },
  });
};

const useCreateTag = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(CREATE_TAG, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.ADD, data: data.createTag });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

const useTag = (id) => {
  const dispatch = useContext(TagDispatchContext);

  return useQuery(
    GET_TAG,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    },
    {
      onCompleted: (data) => {
        dispatch({ type: ACTION_TYPES.VIEW, data: data.tag });
      },
    }
  );
};

const useDeleteTag = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(DELETE_TAG, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.REMOVE, id: data.deleteTag?.id });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

export {
  TagsProvider,
  useTags,
  useCreateTag,
  useTag,
  useDeleteTag,
  useAllTags,
};
