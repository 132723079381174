import { ApolloProvider } from '@apollo/client';
import client from "./client"

const ApolloQueryProvider = ({ children }) => {
    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
}

export {
    ApolloQueryProvider
}