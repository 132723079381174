import React, { useEffect } from "react";

import { useAuth } from "hooks/auth/";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, roles = [], ...props }) => {
  const { user: isAuthenticated, isLoading } = useAuth();

  return (
    !isLoading && (
      <Route
        {...props}
        render={({ location }) => {
          return isAuthenticated ? <Component /> : <Redirect to="/login" />;
        }}
      />
    )
  );
};
export default PrivateRoute;
