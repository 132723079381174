import { gql } from "@apollo/client";

export const GET_INCOMINGS = gql`
  query getIncomings {
    incomings {
      id
      payed_at
      competence
      value
      product {
        name
      }
    }
  }
`;

export const GET_INCOMINGS_PAGINATE = gql`
  query getIncomings(
    $first: Int! = 30
    $page: Int! = 1
    $product_id: String
    $competence: String
  ) {
    paginateIncomings(
      first: $first
      page: $page
      product_id: $product_id
      competence: $competence
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        product {
          name
        }
        competence
        value
      }
    }
  }
`;

export const GET_INCOMING = gql`
  query getIncoming($id: ID!) {
    incoming(id: $id) {
      id
      release_date
      payed_at
      status
      type
      competence
      value
      product {
        name
      }
      imports {
        id
        filename
        path
      }
    }
  }
`;

export const CREATE_INCOMING = gql`
  mutation createIncoming($input: CreateIncoming) {
    createIncoming(input: $input) {
      id
    }
  }
`;

export const UPDATE_INCOMING = gql`
  mutation updateIncoming($input: UpdateIncoming) {
    updateIncoming(input: $input) {
      id
    }
  }
`;

export const DELETE_INCOMING = gql`
  mutation deleteIncoming($id: ID!) {
    deleteIncoming(id: $id) {
      id
    }
  }
`;

export const GET_IMPORT = gql`
  query getImport($id: ID!) {
    getImport(id: $id) {
      url
    }
  }
`;
