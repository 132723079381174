import { useState } from "react";
import AsyncTable from "components/Tables/AsyncTable";
import { useCollaborators } from "hooks/collaborators";
import { usePaginateCollaborators } from "hooks/collaborators";
import DeleteCollaborator from "./DeleteCollaborator";

const columns = [
  {
    Header: "Nome",
    accessor: "name",
  },
  {
    Header: "CPF",
    accessor: "cpf",
  },
  {
    Header: "Telefone",
    accessor: "phone",
  },
  {
    Header: "Tipo",
    accessor: "type",
    Cell: (props) => (
      <div>{props.value === "external" ? "Externo" : "Interno"}</div>
    ),
  },
  {
    Header: "Tipo de pagamento",
    accessor: "payment_method",
    Cell: (props) => <div>{props.value === "account" ? "Conta" : "Pix"}</div>,
  },
  {
    Header: "Função",
    accessor: "purpose",
  },
];

const CollaboratorsTable = () => {
  const {
    loading,
    rows = [{}],
    total,
    perPage,
    currentPage,
  } = useCollaborators();
  const [
    paginateCollaborators,
    { loading: reloading },
  ] = usePaginateCollaborators();
  const [deleteModal, setDeleteModal] = useState(false);
  const [collaborator, setCollaborator] = useState({});

  const fetchData = ({ pageSize, pageIndex, search }) => {
    if (pageSize != perPage) pageIndex = 1;
    paginateCollaborators({
      variables: {
        first: pageSize || 30,
        page: pageIndex || 1,
      },
    });
  };

  const handleDelete = (id) => {
    setCollaborator({ id: id });
    setDeleteModal(true);
  };

  return (
    <>
      <DeleteCollaborator
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(false)}
        collaborator={collaborator}
      />
      <AsyncTable
        isLoading={loading || reloading}
        fetchData={fetchData}
        data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
        columns={columns}
        total={total}
        asyncPage={currentPage}
        baseRoute="colaboradores"
        removeAction={(id) => handleDelete(id)}
        linkRow
      />
    </>
  );
};

export default CollaboratorsTable;
