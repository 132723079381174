import { gql } from '@apollo/client';

export const GET_COLLABORATORS =  gql`
    query getCollaborators {
        collaborators {
            id
            name
            phone
            purpose
            type
            payment_method
        }
    }
`

export const GET_COLLABORATORS_PAGINATE = gql`
    query getCollaborators($first: Int! = 30, $page: Int! = 1) {
        paginateCollaborators(first: $first, page: $page) {
            paginatorInfo {
                count
                currentPage
                firstItem
                perPage
                lastItem
                total
            }
            data {
                id
                name
                cpf
                phone
                purpose
                type
                payment_method
            }
        }
    }
`

export const GET_COLLABORATOR = gql`
    query getCollaborator($id: ID!) {
        collaborator(id: $id) {
            id
            name
            cpf
            phone
            email
            purpose
            journey
            type
            payment_method
            bank
            agency
            account_number
            pix_key
            active
            areas {
                id
                name
            }
        }
    }
`

export const CREATE_COLLABORATOR = gql`
    mutation createCollaborator($input: CreateCollaborator) {
        createCollaborator(input: $input) {
            id
            name
            cpf
            phone
            email
            purpose
            journey
            type
            payment_method
            bank
            agency
            account_number
            pix_key
        }
    }
`

export const UPDATE_COLLABORATOR = gql`
    mutation updateCollaborator($input: UpdateCollaborator) {
        updateCollaborator(input: $input) {
            id
        }
    }
`

export const DELETE_COLLABORATOR = gql`
    mutation deleteCollaborator($id: ID!) {
        deleteCollaborator(id: $id) {
            id
        }
    }
`
