import React from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

import { useDeleteClient } from "hooks/clients";

const DeleteClient = ({ client, closeModal, isOpen }) => {
  const toast = useToast();

  const [removeClient, { isLoading }] = useDeleteClient(
    {
      onSuccess: () => {
        toast({
          title: "Área removido com sucesso.",
          status: "success",
          duration: 5000,
          isClosable: true,
        }),
          closeModal();
      },
      onError: () => {
        toast({
          title: "Ocorreu um erro.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  return (
    <Modal
      isCentered
      onClose={() => closeModal()}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Deletar Cliente</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Tem certeza que deseja remover #{client?.name}
        </ModalBody>
        <ModalFooter>
          <Button mr={3} variant="ghost" onClick={() => closeModal()}>
            Cancelar
          </Button>
          <Button
            isLoading={isLoading}
            colorScheme="red"
            onClick={() => removeClient({variables: {id: client?.id}})}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteClient;
