import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { useCollaborator } from "hooks/collaborators";
import { NavLink, useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  List,
  ListIcon,
  ListItem,
  Skeleton,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { cpfFormat } from "functions/cpfFormat";

const ViewCollaborator = () => {
  const { id } = useParams();
  const { data: { collaborator } = {}, loading } = useCollaborator(id);

  const buttonBg = useColorModeValue("governance.700", "governance.700");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <Card w={{ md: "100%", lg: "50%" }} h="fit-content">
        <CardHeader mb="40px">
          <Flex direction="row" w="100%" justify="space-between">
            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="3px">
              Colaborador
            </Text>
            <Flex>
              <NavLink to={`/sistema/administracao/colaboradores/${id}/editar`}>
                <Button
                  backgroundColor={buttonBg}
                  color="white"
                  _hover={{ bg: "governance.800" }}
                  mr="12px"
                  padding="0 24px"
                >
                  Editar
                </Button>
              </NavLink>
              <NavLink to="/sistema/administracao/colaboradores">
                <Button>Voltar</Button>
              </NavLink>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <VStack spacing="24px">
              <FormControl>
                <FormLabel color="gray.600" fontSize="xs">
                  Nome
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Flex align="center">
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mr="12px"
                    >
                      {collaborator?.name || null}
                    </Text>
                    <Tooltip
                      label={collaborator?.active ? "Ativo" : "Inativo"}
                      placement="top"
                    >
                      <Badge
                        backgroundColor={
                          collaborator?.active ? "green.500" : "red.500"
                        }
                        height="15px"
                        width="15px"
                        borderRadius="50%"
                      ></Badge>
                    </Tooltip>
                  </Flex>
                </Skeleton>
              </FormControl>
              <Flex w="100%">
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    CPF
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!loading}>
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mb="3px"
                    >
                      {collaborator?.cpf ? cpfFormat(collaborator?.cpf) : null}
                    </Text>
                  </Skeleton>
                </FormControl>
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Telefone
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!loading}>
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mb="3px"
                    >
                      {collaborator?.phone || null}
                    </Text>
                  </Skeleton>
                </FormControl>
              </Flex>
              <Flex w="100%">
                {collaborator?.email && (
                  <FormControl>
                    <FormLabel color="gray.600" fontSize="xs">
                      E-mail
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!loading}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        {collaborator?.email || null}
                      </Text>
                    </Skeleton>
                  </FormControl>
                )}
                {collaborator?.purpose && (
                  <FormControl>
                    <FormLabel color="gray.600" fontSize="xs">
                      Função
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!loading}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        {collaborator?.purpose || null}
                      </Text>
                    </Skeleton>
                  </FormControl>
                )}
              </Flex>
              <Flex w="100%">
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Tipo
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!loading}>
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mb="3px"
                    >
                      {collaborator?.type === "external"
                        ? "Externo"
                        : "Interno" || null}
                    </Text>
                  </Skeleton>
                </FormControl>
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Jornada
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!loading}>
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mb="3px"
                    >
                      {collaborator?.journey === "continuous"
                        ? "Contínua"
                        : "Intermitente" || null}
                    </Text>
                  </Skeleton>
                </FormControl>
              </Flex>

              {collaborator?.payment_method === "pix" && (
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Chave Pix
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!loading}>
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mb="3px"
                    >
                      {collaborator?.pix_key || null}
                    </Text>
                  </Skeleton>
                </FormControl>
              )}

              {collaborator?.payment_method === "account" && (
                <>
                  <Flex>
                    <FormControl>
                      <FormLabel color="gray.600" fontSize="xs">
                        Banco
                      </FormLabel>
                      <Skeleton height={"25px"} isLoaded={!loading}>
                        <Text
                          color={textColor}
                          fontSize="lg"
                          fontWeight="bold"
                          mb="3px"
                        >
                          {collaborator?.bank || null}
                        </Text>
                      </Skeleton>
                    </FormControl>
                    <FormControl>
                      <FormLabel color="gray.600" fontSize="xs">
                        Agência
                      </FormLabel>
                      <Skeleton height={"25px"} isLoaded={!loading}>
                        <Text
                          color={textColor}
                          fontSize="lg"
                          fontWeight="bold"
                          mb="3px"
                        >
                          {collaborator?.agency || null}
                        </Text>
                      </Skeleton>
                    </FormControl>
                  </Flex>
                  <FormControl>
                    <FormLabel color="gray.600" fontSize="xs">
                      Número da conta
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!loading}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        {collaborator?.account_number || null}
                      </Text>
                    </Skeleton>
                  </FormControl>
                </>
              )}

              {collaborator?.areas?.length > 0 && (
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Áreas de atuação
                  </FormLabel>
                  <List ml="6px" spacing={3}>
                    {collaborator?.areas?.map((area) => (
                      <ListItem fontWeight="bold">
                        <ListIcon color="governance.700" as={CheckCircleIcon} />
                        <NavLink
                          to={`/sistema/administracao/colaboradores/${area?.id}`}
                        >
                          {area?.name}
                        </NavLink>
                      </ListItem>
                    ))}
                  </List>
                </FormControl>
              )}
            </VStack>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default ViewCollaborator;
