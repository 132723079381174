import { useState } from "react";
import AsyncTable from "components/Tables/AsyncTable";
import { useContacts } from "hooks/contacts";
import { usePaginateContacts } from "hooks/contacts";

import ViewContact from "./ViewContact";
import CreateContact from "./CreateContact";
import EditContact from "./EditContact";
import DeleteContact from "./DeleteContact";

const columns = [
  {
    Header: "Nome",
    accessor: "name",
  },
];

const ContactsTable = ({ clientId, createModal, setCreateModal }) => {
  const { loading, rows = [{}], total, perPage, currentPage } = useContacts();

  const [paginateContacts, { loading: reloading }] = usePaginateContacts();

  const [viewModal, setViewModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [contact, setContact] = useState({});

  const fetchData = ({ pageSize, pageIndex, search }) => {
    if (pageSize != perPage) pageIndex = 1;
    paginateContacts({
      variables: {
        first: pageSize || 5,
        page: pageIndex || 1,
        client_id: clientId,
      },
    });
  };

  const handleView = (id) => {
    setContact(id);
    setViewModal(true);
  };

  const handleDelete = (id) => {
    setContact(id);
    setDeleteModal(true);
  };

  const handleEdit = (id) => {
    setContact(id);
    setUpdateModal(true);
  };

  return (
    <>
      <ViewContact
        isOpen={viewModal}
        closeModal={() => setViewModal(false)}
        id={contact}
      />
      <CreateContact
        isOpen={createModal}
        closeModal={() => setCreateModal(false)}
      />
      <EditContact
        isOpen={updateModal}
        closeModal={() => setUpdateModal(false)}
        id={contact}
      />
      <DeleteContact
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(false)}
        id={contact}
      />
      <AsyncTable
        isLoading={loading || reloading}
        fetchData={fetchData}
        data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
        columns={columns}
        total={total}
        asyncPage={currentPage}
        baseRoute="contatos"
        size={5}
        viewAction={(id) => handleView(id)}
        removeAction={(id) => handleDelete(id)}
        editAction={(id) => handleEdit(id)}
      />
    </>
  );
};

export default ContactsTable;
