import { useEffect, useState } from "react";
import { Flex, FormControl, FormLabel, Select, Text } from "@chakra-ui/react";

import { useAllAreas } from "hooks/areas";

import { getCurrCompetence } from "functions/date";
import { getCompetencesInRange } from "functions/date";

export default function OutgoingsFilters({ filters, setFilter }) {
  const { data: { areas } = {}, loading: loadingAreas } = useAllAreas();
  const [competences, setCompetences] = useState(null);
  const currCompetence = getCurrCompetence();

  const handleFilter = (e, key) => {
    if (e.target.value == "" || e.target.value == null)
      return setFilter({ ...filters, [key]: null });
    return setFilter({ ...filters, [key]: e.target.value });
  };

  useEffect(() => {
    setCompetences(getCompetencesInRange());
  }, []);

  return (
    <Flex maxW="50%" gap="24px" ml="1em">
      <FormControl>
        <FormLabel ml="12px" color="gray.600" fontWeight="bold" fontSize="xs">
          Filtar por Competência
        </FormLabel>
        {competences && (
          <Select
            borderRadius="25px"
            fontSize="md"
            placeholder="Selecione..."
            onChange={(e) => {
              handleFilter(e, "competence");
            }}
          >
            {competences?.map((competence) => (
              <option value={competence}>{competence}</option>
            ))}
          </Select>
        )}
      </FormControl>

      <FormControl>
        <FormLabel ml="12px" color="gray.600" fontWeight="bold" fontSize="xs">
          Filtar por Área
        </FormLabel>
        {!loadingAreas && (
          <Select
          borderRadius="25px"
            fontSize="md"
            placeholder="Selecione..."
            onChange={(e) => {
              handleFilter(e, "area");
            }}
          >
            {areas?.map((area) => (
              <option value={area.id}>{area.name}</option>
            ))}
          </Select>
        )}
      </FormControl>
    </Flex>
  );
}
