import {format as fnsFormat} from "date-fns"
import ptBR from "date-fns/locale/pt-BR"

const dateFormat = (value, format = "dd/MM/yyyy") => {
    try {
        if (!value) return value;
        if (typeof value === 'string' && value.length < 11) value += ' 12:00' // Impede que a nova data seja referente ao dia anterior 
        return fnsFormat(new Date(value), format, {locale: ptBR});
    } catch (e) {
        return value;
    }
}

export default dateFormat