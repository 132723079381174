const ACTION_TYPES = {
  ME: "ME",
};

const reducers = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.ME: {
      return {
        user: action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducers, ACTION_TYPES };
