import React from "react";

import { NavLink } from "react-router-dom";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  List,
  ListIcon,
  ListItem,
  Skeleton,
  Text,
  useColorModeValue,
  VStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useProduct } from "hooks/products";
import { CheckCircleIcon } from "@chakra-ui/icons";
import dateFormat from "functions/dateFormat";

const ViewProduct = ({ id, isOpen, closeModal }) => {
  const { data: { product } = {}, loading } = useProduct(id);

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Modal
      isCentered
      onClose={() => closeModal()}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Produto</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" w="100%" padding="1em">
            <VStack spacing="24px">
              <FormControl>
                <FormLabel color="gray.600" fontSize="xs">
                  Nome
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Flex align="center">
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mr="12px"
                    >
                      {product?.name || null}
                    </Text>
                  </Flex>
                </Skeleton>
              </FormControl>

              {product?.url && (
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    URL
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!loading}>
                    <Flex align="center">
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mr="12px"
                      >
                        {product?.url || null}
                      </Text>
                    </Flex>
                  </Skeleton>
                </FormControl>
              )}

              {product?.description && (
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Descrição
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!loading}>
                    <Flex align="center">
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mr="12px"
                      >
                        {product?.description || null}
                      </Text>
                    </Flex>
                  </Skeleton>
                </FormControl>
              )}

              {product?.payment_method && (
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Tipo de pagamento
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!loading}>
                    <Flex align="center">
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mr="12px"
                      >
                        {product?.payment_method || null}
                      </Text>
                    </Flex>
                  </Skeleton>
                </FormControl>
              )}

              {(product?.start_date || product?.renovation_date) && (
                <Grid templateColumns="repeat(2, 1fr)" w="100%">
                  {product?.start_date && (
                    <FormControl>
                      <FormLabel color="gray.600" fontSize="xs">
                        Data de ínicio
                      </FormLabel>
                      <Skeleton height={"25px"} isLoaded={!loading}>
                        <Flex align="center">
                          <Text
                            color={textColor}
                            fontSize="lg"
                            fontWeight="bold"
                            mr="12px"
                          >
                            {product?.start_date
                              ? dateFormat(product.start_date)
                              : null}
                          </Text>
                        </Flex>
                      </Skeleton>
                    </FormControl>
                  )}

                  {product?.renovation_date && (
                    <FormControl>
                      <FormLabel color="gray.600" fontSize="xs">
                        Data de renovação
                      </FormLabel>
                      <Skeleton height={"25px"} isLoaded={!loading}>
                        <Flex align="center">
                          <Text
                            color={textColor}
                            fontSize="lg"
                            fontWeight="bold"
                            mr="12px"
                          >
                            {product?.renovation_date
                              ? dateFormat(product.renovation_date)
                              : null}
                          </Text>
                        </Flex>
                      </Skeleton>
                    </FormControl>
                  )}
                </Grid>
              )}

              {product?.clients?.length > 0 && (
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Vinculado aos clientes
                  </FormLabel>
                  <Flex align="center">
                    <List ml="6px" spacing={3}>
                      {product?.clients?.map((client) => (
                        <ListItem fontWeight="bold">
                          <ListIcon
                            color="governance.700"
                            as={CheckCircleIcon}
                          />
                          <NavLink
                            to={`/sistema/administracao/clientes/${client?.id}`}
                          >
                            {client?.name}
                          </NavLink>
                        </ListItem>
                      ))}
                    </List>
                  </Flex>
                </FormControl>
              )}
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewProduct;
