import React from "react";

import {
  Flex,
  FormControl,
  FormLabel,
  Grid,
  List,
  ListIcon,
  ListItem,
  Skeleton,
  Text,
  useColorModeValue,
  VStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useContact } from "hooks/contacts";
import { CheckCircleIcon } from "@chakra-ui/icons";

const ViewContact = ({ id, isOpen, closeModal }) => {
  const { data: { contact } = {}, loading } = useContact(id);

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Modal
      isCentered
      onClose={() => closeModal()}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Contato</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" w="100%" padding="1em">
            <VStack spacing="24px">
              <FormControl>
                <FormLabel color="gray.600" fontSize="xs">
                  Nome
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Flex align="center">
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mr="12px"
                    >
                      {contact?.name || null}
                    </Text>
                  </Flex>
                </Skeleton>
              </FormControl>

              <Grid templateColumns="repeat(2, 1fr)" w="100%">
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Telefone
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!loading}>
                    <Flex align="center">
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mr="12px"
                      >
                        {contact?.phone || null}
                      </Text>
                    </Flex>
                  </Skeleton>
                </FormControl>

                {contact?.email && (
                  <FormControl>
                    <FormLabel color="gray.600" fontSize="xs">
                      Email
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!loading}>
                      <Flex align="center">
                        <Text
                          color={textColor}
                          fontSize="lg"
                          fontWeight="bold"
                          mr="12px"
                        >
                          {contact?.email || null}
                        </Text>
                      </Flex>
                    </Skeleton>
                  </FormControl>
                )}
              </Grid>

              <FormControl>
                <FormLabel color="gray.600" fontSize="xs">
                  Função
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Flex align="center">
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mr="12px"
                    >
                      {contact?.function || null}
                    </Text>
                  </Flex>
                </Skeleton>
              </FormControl>
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewContact;
