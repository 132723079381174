import React from "react";
import { NavLink, useHistory } from "react-router-dom";

import {
  Button,
  Flex,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ClientForm from "./ClientForm";
import { clientSchema } from "./yupSchema";
import { useCreateClient } from "hooks/clients";

const CreateClient = () => {
  const toast = useToast();
  const history = useHistory();

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(clientSchema),
  });

  const { handleSubmit, reset } = methods;

  const [createClient, { data, loading, error }] = useCreateClient({
    onSuccess: (data) => {
      reset();
      toast({
        title: "Cliente criado com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      }),
        history.push(`/sistema/administracao/clientes/${data.createClient.id}`);
    },
    onError: (err) => {
      console.log(err);
      toast({
        title: "Ocorreu um erro",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleDataBeforeSubmit = (data) => {
    data.cnpj = data?.cnpj?.replace(/[^0-9]/g, "");
    return data;
  };

  const onSubmit = (data) => {
    data = handleDataBeforeSubmit(data);
    const variables = {
      input: {
        ...data,
      },
    };

    createClient({ variables: variables });
  };

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <Card w={{ md: "100%", lg: "50%" }}>
        <CardHeader mb="40px">
          <Flex direction="column">
            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="3px">
              Criar Cliente
            </Text>
            <Text color="gray.400" fontWeight="normal" fontSize="sm">
              Preencha os campos para realizar o cadastro
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <FormProvider {...methods}>
            <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
              <Flex direction="column" w="100%">
                <ClientForm />
                <Flex mt="24px" align={"center"} justify={"flex-end"}>
                  <NavLink to="/sistema/administracao/clientes">
                    <Text
                      mr="20px"
                      fontSize="xs"
                      color={textColor}
                      fontWeight="bold"
                    >
                      Cancelar
                    </Text>
                  </NavLink>
                  <Button
                    isLoading={loading}
                    type="submit"
                    variant="no-hover"
                    bg="governance.700"
                    alignSelf="flex-end"
                    w="100px"
                    h="35px"
                  >
                    <Text fontSize="xs" color="#fff" fontWeight="bold">
                      Criar
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </form>
          </FormProvider>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default CreateClient;
