import React from "react";

import { NavLink, useHistory, useParams } from "react-router-dom";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { outgoingSchema } from "./yupSchema";

import {
  Button,
  Flex,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import OutgoingForm, { handleDataBeforeSubmit } from "./OutgoingForm";
import { useUpdateOutgoing } from "hooks/outgoings";
import { useOutgoing } from "hooks/outgoings";
import dateFormat from "functions/dateFormat";

const EditOutgoing = () => {
  const { id } = useParams();
  const { data: { outgoing } = {}, loading } = useOutgoing(id);

  const history = useHistory();

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(outgoingSchema),
  });
  const toast = useToast();

  const { reset, handleSubmit } = methods;

  const [updateOutgoing, { loading: loadingUpdate, error }] = useUpdateOutgoing({
    onSuccess: (data) => {
      reset();
      toast({
        title: "Gasto atualizado com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      }),
        history.push(
          `/sistema/financeiro/saidas/${data.updateOutgoing.id}`
        );
    },
    onError: (err) => {
      console.log(err);
      toast({
        title: "Ocorreu um erro",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const onSubmit = (data) => {
    data = handleDataBeforeSubmit(data);
    const variables = {
      input: {
        id: id,
        ...data,
      },
    };

    updateOutgoing({ variables: variables });
  };

  const buttonBg = useColorModeValue("governance.700", "governance.700");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <Card w={{ md: "100%", lg: "50%" }}>
        <CardHeader mb="40px">
          <Flex direction="column">
            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="3px">
              Editar Produto
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <FormProvider {...methods}>
            <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
              <Flex direction="column" w="100%">
                {loading ? (
                  <>
                    <Flex
                      width="100%"
                      height="100%"
                      justifyContent="center"
                      alignItems="center"
                      fontSize="30px"
                    >
                      <Text fontSize="30px">
                        <Spinner size="xl" w="80px" h="80px" />
                      </Text>
                    </Flex>
                  </>
                ) : (
                  <OutgoingForm defaultValues={outgoing} />
                )}
                <Flex mt="24px" align={"center"} justify={"flex-end"}>
                  <NavLink to={`/sistema/financeiro/saidas/${id}`}>
                    <Text
                      mr="20px"
                      fontSize="xs"
                      color={textColor}
                      fontWeight="bold"
                    >
                      Cancelar
                    </Text>
                  </NavLink>
                  <Button
                    isLoading={loadingUpdate}
                    type="submit"
                    variant="no-hover"
                    bg={buttonBg}
                    alignSelf="flex-end"
                    w="100px"
                    h="35px"
                  >
                    <Text fontSize="xs" color="#fff" fontWeight="bold">
                      Atualizar
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </form>
          </FormProvider>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default EditOutgoing;
