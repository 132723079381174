import { useEffect, useState } from "react";
import AsyncTable from "components/Tables/AsyncTable";
import { useOutgoings } from "hooks/outgoings";
import { usePaginateOutgoings } from "hooks/outgoings";
import DeleteOutgoing from "./DeleteOutgoing";
import dateFormat from "functions/dateFormat";
import { formatCurrency } from "functions/currency";
import OutgoingsFilters from "./components/Filters";
import { competenceFormat } from "functions/date";
import { competenceToISO8601 } from "functions/date";

const columns = [
  {
    Header: "Data de Pagamento",
    accessor: "payed_at",
    Cell: (props) => <div>{dateFormat(props.value)}</div>,
  },
  {
    Header: "Competência",
    accessor: "competence",
    Cell: (props) => (
      <div>
        {props.value
          ? dateFormat(props.value).split("/").slice(1).join("/")
          : ""}
      </div>
    ),
  },
  {
    Header: "Valor",
    accessor: "value",
    Cell: (props) => (
      <div>{props.value ? formatCurrency(props.value) : "R$ 0,00"}</div>
    ),
  },
  {
    Header: "Área",
    accessor: "area.name",
  },
];

const OutgoingsTable = () => {
  const { loading, rows = [{}], total, perPage, currentPage } = useOutgoings();

  const [paginateOutgoings, { loading: reloading }] = usePaginateOutgoings();
  const [filters, setFilters] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [outgoing, setOutgoing] = useState({});

  const fetchData = ({ pageSize, pageIndex, search }) => {
    if (pageSize != perPage) pageIndex = 1;
    paginateOutgoings({
      variables: {
        first: pageSize || 30,
        page: pageIndex || 1,
        ...(filters?.competence ? {competence: competenceToISO8601(filters.competence)} : null),
        ...(filters?.area ? {area_id: filters.area} : null),
      },
    });
  };

  useEffect(() => {
    fetchData(1, 30)
  }, [filters])

  const handleDelete = (id) => {
    setOutgoing({ id: id });
    setDeleteModal(true);
  };

  return (
    <>
      <OutgoingsFilters filters={filters} setFilter={setFilters} />
      <DeleteOutgoing
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(false)}
        outgoing={outgoing}
      />
      <AsyncTable
        isLoading={loading || reloading}
        fetchData={fetchData}
        data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
        columns={columns}
        total={total}
        asyncPage={currentPage}
        baseRoute="saidas"
        removeAction={(id) => handleDelete(id)}
        linkRow
      />
    </>
  );
};

export default OutgoingsTable;
