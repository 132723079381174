import { gql } from "@apollo/client";

export const GET_CONTACTS = gql`
  query getContacts {
    contacts {
      id
      name
    }
  }
`;

export const GET_CONTACTS_PAGINATE = gql`
  query getContacts($first: Int! = 30, $page: Int! = 1) {
    paginateContacts(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        name
      }
    }
  }
`;

export const GET_CONTACT = gql`
  query getContact($id: ID!) {
    contact(id: $id) {
      id
      name
      phone
      email
      function
      client_id
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation createContact($input: CreateContact) {
    createContact(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation updateContact($input: UpdateContact) {
    updateContact(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation deleteContact($id: ID!) {
    deleteContact(id: $id) {
      id
    }
  }
`;
