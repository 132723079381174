import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Switch, Route } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";

import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import Login from "views/Auth/Login";

import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme.js";

import { ApolloQueryProvider } from "services/apollo";
import AppProvider from "providers/appProvider";

ReactDOM.render(
  <ApolloQueryProvider>
    <AppProvider>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/sistema" component={AdminLayout} />
            <Redirect from="/" to="/sistema" />
          </Switch>
        </BrowserRouter>
      </ChakraProvider>
    </AppProvider>
  </ApolloQueryProvider>,
  document.getElementById("root")
);
