import React from "react";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

const ContactForm = ({ defaultValues = {} }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputHover = useColorModeValue("governance.800", "governance.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.name}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Nome
          </FormLabel>
          <Input
            {...register("name")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.name}
          />
          {errors.name && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.phone}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Telefone
          </FormLabel>
          <Input
            {...register("phone")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.phone}
          />
          {errors.phone && (
            <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.email}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            E-mail
          </FormLabel>
          <Input
            {...register("email")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.email}
          />
          {errors.email && (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.function}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Função
          </FormLabel>
          <Input
            {...register("function")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.function}
          />
          {errors.function && (
            <FormErrorMessage>{errors.function.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
    </>
  );
};

export default ContactForm;
