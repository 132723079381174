import { useState } from "react";
import AsyncTable from "components/Tables/AsyncTable";
import { useAreas } from "hooks/areas";
import { usePaginateAreas } from "hooks/areas";
import DeleteArea from "./DeleteArea";

const columns = [
  {
    Header: "Nome",
    accessor: "name",
  },
];

const AreasTable = () => {
  const {
    loading,
    rows = [{}],
    total,
    perPage,
    currentPage,
  } = useAreas();
  const [
    paginateAreas,
    { loading: reloading },
  ] = usePaginateAreas();
  const [deleteModal, setDeleteModal] = useState(false);
  const [area, setArea] = useState({});

  const fetchData = ({ pageSize, pageIndex, search }) => {
    if (pageSize != perPage) pageIndex = 1;
    paginateAreas({
      variables: {
        first: pageSize || 30,
        page: pageIndex || 1,
      },
    });
  };

  const handleDelete = (id) => {
    setArea({ id: id });
    setDeleteModal(true);
  };

  return (
    <>
      <DeleteArea
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(false)}
        area={area}
      />
      <AsyncTable
        isLoading={loading || reloading}
        fetchData={fetchData}
        data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
        columns={columns}
        total={total}
        asyncPage={currentPage}
        baseRoute="areas"
        removeAction={(id) => handleDelete(id)}
        linkRow
      />
    </>
  );
};

export default AreasTable;
