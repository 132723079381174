import React from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

import { useDeleteProduct } from "hooks/products";

const DeleteProduct = ({ id, closeModal, isOpen }) => {
  const toast = useToast();

  const [removeProduct, { isLoading }] = useDeleteProduct({
    onSuccess: () => {
      toast({
        title: "Produto removido com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      }),
        closeModal();
    },
    onError: () => {
      toast({
        title: "Ocorreu um erro.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  return (
    <Modal
      isCentered
      onClose={() => closeModal()}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Deletar Produto</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Tem certeza que deseja remover #{id}</ModalBody>
        <ModalFooter>
          <Button mr={3} variant="ghost" onClick={() => closeModal()}>
            Cancelar
          </Button>
          <Button
            isLoading={isLoading}
            colorScheme="red"
            onClick={() => removeProduct({ variables: { id: id } })}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteProduct;
