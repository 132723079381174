import React, { useState, useEffect } from "react";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Select,
  Skeleton,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useAllAreas } from "hooks/areas";
import MultiSelect from "components/MultiSelect/MultiSelect";
import InputMask from "react-input-mask";
import dateFormat from "functions/dateFormat";
import { formatCurrency } from "functions/currency";
import { useAllTags } from "hooks/tags";
import TagModal from "./components/TagModal";
import { getCurrCompetence } from "functions/date";
import { getCompetencesInRange } from "functions/date";
import { competenceToISO8601 } from "functions/date";

const getDate = (value) => {
  const splitted = value.split(" ");
  const date = splitted[0].split("/");
  const dateTime = new Date(date[2], date[1] - 1, date[0]);

  return `${dateFormat(dateTime, "Y-MM-dd")}`;
};

export const handleDataBeforeSubmit = (data) => {
  if (data?.payed_at) {
    data.payed_at = getDate(data.payed_at);
  }

  if (data?.competence) {
    data.competence = competenceToISO8601(data.competence);
  }

  if (data?.value) {
    data.value = data.value.replace(/[^0-9\,]/g, "").replace(",", ".");
  }

  return data;
};

const OutgoingForm = ({ defaultValues = {} }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [competences, setCompetences] = useState(null);
  const currCompetence = getCurrCompetence();
  const { data: { areas } = {}, loading: loadingAreas } = useAllAreas();
  const [getTags, { data: { tags } = {}, loading: loadingTags }] = useAllTags();
  const [value, setFieldValue] = useState(
    defaultValues?.value ? `${formatCurrency(defaultValues.value)}` : "R$ 0,00"
  );

  const inputHover = useColorModeValue("governance.800", "governance.800");
  const textColor = useColorModeValue("gray.700", "white");

  const [tagModal, setTagModal] = useState(false);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatCurrency(inputValue);
    setFieldValue(formattedValue);
  };

  useEffect(() => {
    setCompetences(getCompetencesInRange());
    getTags();
  }, []);

  useEffect(() => {
    getTags();
  }, [tagModal]);

  return (
    <>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.payed_at}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Data de pagamento
          </FormLabel>
          <Input
            {...register("payed_at")}
            as={InputMask}
            mask="99/99/9999"
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={
              defaultValues?.payed_at
                ? dateFormat(defaultValues?.payed_at)
                : null
            }
          />
          {errors.payed_at && (
            <FormErrorMessage>{errors.payed_at.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={errors.competence}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Competência
          </FormLabel>
          {competences && (
            <Select
              {...register("competence")}
              focusBorderColor={inputHover}
              borderRadius="8px"
              fontSize="md"
              defaultValue={
                defaultValues?.competence
                  ? dateFormat(defaultValues?.competence)
                      .split("/")
                      .slice(1)
                      .join("/")
                  : currCompetence
              }
            >
              {competences?.map((competence) => (
                <option value={competence}>{competence}</option>
              ))}
            </Select>
          )}
          {errors.competence && (
            <FormErrorMessage>{errors.competence.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.value}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Valor
          </FormLabel>
          <Input
            {...register("value")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            value={value}
            onChange={(e) => handleChange(e)}
            defaultValue={defaultValues?.value}
          />
          {errors.value && (
            <FormErrorMessage>{errors.value.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.description}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Descrição
          </FormLabel>
          <Textarea
            {...register("description")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.description}
          />
          {errors.description && (
            <FormErrorMessage>{errors.description.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
        gap="24px"
        mt="24px"
      >
        <FormControl>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Area
          </FormLabel>
          {loadingAreas ? (
            <Skeleton height="20px" />
          ) : (
            <Select
              placeholder="Selecione..."
              {...register("area_id")}
              defaultValue={defaultValues?.area_id}
            >
              {areas?.map((area) => (
                <option value={area?.id}>{area?.name}</option>
              ))}
            </Select>
          )}
        </FormControl>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
        gap="24px"
        mt="24px"
      >
        <FormControl>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Tags
          </FormLabel>
          {loadingTags ? (
            <Skeleton height="20px" />
          ) : (
            <MultiSelect
              fieldName={"tags"}
              options={tags}
              defaultSelection={defaultValues?.tags}
              actionOption={{
                name: "Adicionar uma tag",
                callback: () => {
                  setTagModal(true);
                },
              }}
            />
          )}
        </FormControl>
      </Grid>
      {tagModal && <TagModal onClose={() => setTagModal(false)} />}
    </>
  );
};

export default OutgoingForm;
