import React, { useState, useEffect } from "react";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import InputMask from "react-input-mask";
import dateFormat from "functions/dateFormat";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ProductForm = ({ defaultValues = {} }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const inputHover = useColorModeValue("governance.800", "governance.800");
  const textColor = useColorModeValue("gray.700", "white");

  console.log(defaultValues);

  return (
    <>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.name}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Nome
          </FormLabel>
          <Input
            {...register("name")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.name}
          />
          {errors.name && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.url}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            URL
          </FormLabel>
          <Input
            {...register("url")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.url}
          />
          {errors.url && (
            <FormErrorMessage>{errors.url.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.description}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Descrição
          </FormLabel>
          <Textarea
            {...register("description")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.description}
          />
          {errors.description && (
            <FormErrorMessage>{errors.description.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.start_date}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Data de ínicio
          </FormLabel>
          <Input
            {...register("start_date")}
            as={InputMask}
            mask="99/99/9999"
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={
              defaultValues?.start_date
                ? dateFormat(defaultValues?.start_date)
                : null
            }
          />
          {errors.start_date && (
            <FormErrorMessage>{errors.start_date.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={errors.renovation_date}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Data de renovação
          </FormLabel>
          <Input
            {...register("renovation_date")}
            as={InputMask}
            mask="99/99/9999"
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={
              defaultValues?.renovation_date
                ? dateFormat(defaultValues?.renovation_date)
                : null
            }
          />
          {errors.renovation_date && (
            <FormErrorMessage>
              {errors.renovation_date.message}
            </FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.payment_method}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Tipo de pagamento
          </FormLabel>
          <Input
            {...register("payment_method")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.payment_method}
          />
          {errors.payment_method && (
            <FormErrorMessage>{errors.payment_method.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
    </>
  );
};

export default ProductForm;
