import React, { useState, useEffect } from "react";

import {
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Select,
  Skeleton,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import InputMask from "react-input-mask";
import dateFormat from "functions/dateFormat";
import { formatCurrency } from "functions/currency";
import { getCurrCompetence } from "functions/date";
import { getCompetencesInRange } from "functions/date";
import { competenceToISO8601 } from "functions/date";
import { useAllClients } from "hooks/clients";
import Dropzone, { useDropzone } from "react-dropzone";

const getDate = (value) => {
  const splitted = value.split(" ");
  const date = splitted[0].split("/");
  const dateTime = new Date(date[2], date[1] - 1, date[0]);

  return `${dateFormat(dateTime, "Y-MM-dd")}`;
};

export const handleDataBeforeSubmit = (data) => {
  delete data.client_id;

  if (data?.payed_at) {
    data.payed_at = getDate(data.payed_at);
  }

  if (data?.release_date) {
    data.release_date = getDate(data.release_date);
  }

  if (data?.competence) {
    data.competence = competenceToISO8601(data.competence);
  }

  if (data?.value) {
    data.value = data.value.replace(/[^0-9\,]/g, "").replace(",", ".");
  }

  return data;
};

const IncomingForm = ({ defaultValues = {} }) => {
  const {
    register,
    watch,
    setValue,
    unregister,
    formState: { errors },
  } = useFormContext();

  const [competences, setCompetences] = useState(null);
  const currCompetence = getCurrCompetence();

  const [value, setFieldValue] = useState(
    defaultValues?.value ? `${formatCurrency(defaultValues.value)}` : "R$ 0,00"
  );

  const [files, setAccepted] = React.useState([]);
  const [rejected, setRejected] = React.useState(false);

  const handleDropAccept = (acceptedFiles) => {
    setAccepted(acceptedFiles);
  };

  const handleDropReject = (rejectedFiles) => {
    setRejected(true);
  };

  const {
    data: { clients = [] } = [],
    loading: loadingClients,
  } = useAllClients();

  const selectedClient = watch("client_id");

  const inputHover = useColorModeValue("governance.800", "governance.800");
  const textColor = useColorModeValue("gray.700", "white");

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatCurrency(inputValue);
    setFieldValue(formattedValue);
  };

  useEffect(() => {
    setCompetences(getCompetencesInRange());
  }, []);

  useEffect(() => {
    setValue("imports", files);
    if (!files) unregister("imports");
  }, [files]);

  return (
    <>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.release_date}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Data do lançamento
          </FormLabel>
          <Input
            {...register("release_date")}
            as={InputMask}
            mask="99/99/9999"
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={
              defaultValues?.release_date
                ? dateFormat(defaultValues?.release_date)
                : null
            }
          />
          {errors.release_date && (
            <FormErrorMessage>{errors.release_date.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={errors.payed_at}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Data de pagamento
          </FormLabel>
          <Input
            {...register("payed_at")}
            as={InputMask}
            mask="99/99/9999"
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={
              defaultValues?.payed_at
                ? dateFormat(defaultValues?.payed_at)
                : null
            }
          />
          {errors.payed_at && (
            <FormErrorMessage>{errors.payed_at.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={errors.competence}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Competência
          </FormLabel>
          {competences && (
            <Select
              {...register("competence")}
              focusBorderColor={inputHover}
              borderRadius="8px"
              fontSize="md"
              defaultValue={
                defaultValues?.competence
                  ? dateFormat(defaultValues?.competence)
                      .split("/")
                      .slice(1)
                      .join("/")
                  : currCompetence
              }
            >
              {competences?.map((competence) => (
                <option value={competence}>{competence}</option>
              ))}
            </Select>
          )}
          {errors.competence && (
            <FormErrorMessage>{errors.competence.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={errors.value}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Valor
          </FormLabel>
          <Input
            {...register("value")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            value={value}
            onChange={(e) => handleChange(e)}
            defaultValue={defaultValues?.value}
          />
          {errors.value && (
            <FormErrorMessage>{errors.value.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={errors.status}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Status
          </FormLabel>
          <Select
            placeholder="Selecione..."
            {...register("status")}
            defaultValue={defaultValues?.status}
          >
            <option value="sent">Enviado</option>
            <option value="payed">Pago</option>
          </Select>
          {errors.status && (
            <FormErrorMessage>{errors.status.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={errors.type}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Tipo
          </FormLabel>
          <Select
            placeholder="Selecione..."
            {...register("type")}
            defaultValue={defaultValues?.type}
          >
            <option value="franchise">Franquia</option>
            <option value="recurrent">Recorrente</option>
            <option value="project">Projeto</option>
          </Select>
          {errors.type && (
            <FormErrorMessage>{errors.type.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Cliente
          </FormLabel>
          {loadingClients ? (
            <Skeleton height="20px" />
          ) : (
            <Select
              placeholder="Selecione..."
              {...register("client_id")}
              defaultValue={defaultValues?.product?.client?.id}
            >
              {clients?.map((clients) => (
                <option value={clients?.id}>{clients?.name}</option>
              ))}
            </Select>
          )}
        </FormControl>
        <FormControl>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Produto
          </FormLabel>
          {loadingClients ? (
            <Skeleton height="20px" />
          ) : (
            <Select
              placeholder="Selecione..."
              disabled={!selectedClient}
              {...register("product_id")}
              defaultValue={defaultValues?.product_id}
            >
              {clients
                .find((c) => c?.id === selectedClient)
                ?.products?.map((product) => (
                  <option value={product?.id}>{product?.name}</option>
                ))}
            </Select>
          )}
        </FormControl>
      </Grid>
      <FormControl>
        <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
          Anexo
        </FormLabel>
        <Center>
          <Flex
            justify="center"
            align="center"
            border="1px dashed #C7CCD4"
            borderRadius="15px"
            w="99%"
            mb="12px"
            padding="1em"
            minH="224px"
            cursor="pointer"
          >
            <Dropzone
              onDropAccepted={handleDropAccept}
              onDropRejected={handleDropReject}
              accept={["text/csv"]}
              onDrop={() => setRejected(false)}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  {files.length === 0 ? (
                    <p>Arraste ou selecione um ou mais arquivos</p>
                  ) : (
                    <p>{`Arquivo${(files.length > 1 && "s") || ""} selecionado${
                      (files.length > 1 && "s") || ""
                    }: ${files.length}`}</p>
                  )}
                </div>
              )}
            </Dropzone>
          </Flex>
        </Center>
        {rejected && (
          <Text color="red.500">
            Alguns dos arquivos não foram aceitos, utilize apenas arquivos do
            tipo CSV
          </Text>
        )}
      </FormControl>
    </>
  );
};

export default IncomingForm;
