import React from "react";
import { createContext, useContext, useReducer } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  GET_CLIENTS,
  GET_CLIENTS_PAGINATE,
  GET_CLIENT,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
} from "graphql/clients";
import { ACTION_TYPES, reducers } from "./reducers";

const ClientsStateContext = createContext();
const ClientDispatchContext = createContext();

const ClientsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {
    rows: [],
    first: 15,
    page: 1,
  });

  const { data, loading, error } = useQuery(GET_CLIENTS_PAGINATE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.PAGINATE, data: data.paginateClients });
    },
  });

  return (
    <ClientsStateContext.Provider value={{ data, loading, error, ...state }}>
      <ClientDispatchContext.Provider value={dispatch}>
        {children}
      </ClientDispatchContext.Provider>
    </ClientsStateContext.Provider>
  );
};

const useClients = () => {
  const context = useContext(ClientsStateContext);

  if (!context)
    throw new Error("useClients must be used within an ClientsProvider");

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(ClientDispatchContext);

  if (dispatch === undefined)
    throw new Error("useDispatch must be used within a ClientsProvider");

  return dispatch;
};

const usePaginateClients = () => {
  const dispatch = useDispatch();

  return useLazyQuery(GET_CLIENTS_PAGINATE, {
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.PAGINATE, data: data.paginateClients });
    },
  });
};

const useAllClients = () => {
  const dispatch = useDispatch();

  return useQuery(GET_CLIENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.ALL, data: data.areas });
    },
  });
};

const useCreateClient = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(CREATE_CLIENT, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.ADD, data: data.createClient });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

const useClient = (id) => {
  const dispatch = useContext(ClientDispatchContext);

  return useQuery(
    GET_CLIENT,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    },
    {
      onCompleted: (data) => {
        dispatch({ type: ACTION_TYPES.VIEW, data: data.area });
      },
    }
  );
};

const useUpdateClient = (options = {}) => {
  const dispatch = useContext(ClientDispatchContext);
  const { onSuccess, onError } = options;

  return useMutation(UPDATE_CLIENT, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateClient });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
    refetchQueries: [
      {
        query: GET_CLIENTS_PAGINATE,
      },
    ],
  });
};

const useDeleteClient = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(DELETE_CLIENT, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.REMOVE, id: data.deleteClient?.id });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

export {
  ClientsProvider,
  useClients,
  useCreateClient,
  usePaginateClients,
  useClient,
  useUpdateClient,
  useDeleteClient,
  useAllClients,
};
