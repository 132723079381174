import { useState } from "react";
import AsyncTable from "components/Tables/AsyncTable";
import { useClients } from "hooks/clients";
import { usePaginateClients } from "hooks/clients";
import DeleteClient from "./DeleteClient";

const columns = [
  {
    Header: "Nome",
    accessor: "name",
  },
  {
    Header: "CNPJ",
    accessor: "cnpj",
  },
];

const ClientsTable = () => {
  const { loading, rows = [{}], total, perPage, currentPage } = useClients();

  const [paginateClients, { loading: reloading }] = usePaginateClients();
  const [deleteModal, setDeleteModal] = useState(false);
  const [client, setClient] = useState({});

  const fetchData = ({ pageSize, pageIndex, search }) => {
    if (pageSize != perPage) pageIndex = 1;
    paginateClients({
      variables: {
        first: pageSize || 30,
        page: pageIndex || 1,
      },
    });
  };

  const handleDelete = (id) => {
    setClient({ id: id });
    setDeleteModal(true);
  };

  return (
    <>
      <DeleteClient
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(false)}
        client={client}
      />
      <AsyncTable
        isLoading={loading || reloading}
        fetchData={fetchData}
        data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
        columns={columns}
        total={total}
        asyncPage={currentPage}
        baseRoute="clientes"
        removeAction={(id) => handleDelete(id)}
        linkRow
      />
    </>
  );
};

export default ClientsTable;
