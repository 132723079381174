import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { API } from "services/api";

const ERROR_LINK = onError(({ networkError }) => {
  if (
    networkError?.statusCode === 401 &&
    localStorage.getItem("governance_token")
  ) {
    localStorage.removeItem("governance_token");
    location.reload();
  }
});

const HTTP_LINK = new HttpLink({
  uri: process.env.REACT_APP_API_URL + "/api/graphql",
  headers: {
    authorization: "Bearer " + window.localStorage.getItem("governance_token"),
    api: "Bearer " + window.localStorage.getItem(""),
  },
});

const UPLOAD_LINK = createUploadLink({
  uri: process.env.REACT_APP_API_URL + "/api/graphql",
  headers: {
    authorization: "Bearer " + window.localStorage.getItem("governance_token"),
    api: "Bearer " + window.localStorage.getItem(""),
  },
});

const APP_LINK = from([ERROR_LINK, HTTP_LINK]);

const CLIENT = new ApolloClient({
  link: ApolloLink.from([ERROR_LINK, UPLOAD_LINK, HTTP_LINK]),
  cache: new InMemoryCache(),
});

export default CLIENT;
