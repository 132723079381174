import dateFormat from "./dateFormat";
import { aditionalZero, mod } from "./utilities";

export const getCompetencesInRange = (range = 5) => {
  const competences = [];
  const currDate = new Date();
  const currMonth = currDate.getMonth();
  const currYear = currDate.getFullYear();

  for (let i = currMonth - range; i <= currMonth + range; i++) {
    if (i < 0) {
      competences.push(`${aditionalZero(mod(i, 12) + 1)}/${currYear - 1}`);
    } else if (i > 12) {
      competences.push(`${aditionalZero(mod(i, 12) + 1)}/${currYear + 1}`);
    } else {
      competences.push(`${aditionalZero(i + 1)}/${currYear}`);
    }
  }

  return competences;
};

export function getCurrCompetence() {
  const currDate = new Date();
  const currMonth = currDate.getMonth() + 1;
  const currYear = currDate.getFullYear();

  return `${aditionalZero(currMonth)}/${currYear}`;
}

export const competenceToISO8601 = (value) => {
  const splitted = value.split(" ");
  const date = splitted[0].split("/");
  const dateTime = new Date(date[1], date[0] - 1, 1);

  return `${dateFormat(dateTime, "Y-MM-dd")}`;
};
