import { ClientsProvider as Provider } from "hooks/clients/index";
import { ContactsProvider } from "hooks/contacts";
import { ProductsProvider } from "hooks/products";
import { ApolloQueryProvider } from "services/apollo";

const ClientsProvider = ({ children }) => {
  return (
    <ApolloQueryProvider>
      <ContactsProvider>
        <ProductsProvider>
          <Provider>{children}</Provider>
        </ProductsProvider>
      </ContactsProvider>
    </ApolloQueryProvider>
  );
};

export default ClientsProvider;
