import React from "react";
import { createContext, useContext, useReducer } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  GET_PRODUCTS,
  GET_PRODUCTS_PAGINATE,
  GET_PRODUCT,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
} from "graphql/products";
import { ACTION_TYPES, reducers } from "./reducers";

const ProductsStateContext = createContext();
const ProductDispatchContext = createContext();

const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {
    data: [],
    loading: false,
    error: false,
    rows: [],
    first: 5,
    page: 1,
  });

  return (
    <ProductsStateContext.Provider value={{ ...state }}>
      <ProductDispatchContext.Provider value={dispatch}>
        {children}
      </ProductDispatchContext.Provider>
    </ProductsStateContext.Provider>
  );
};

const useProducts = () => {
  const context = useContext(ProductsStateContext);

  if (!context)
    throw new Error("useProducts must be used within an ProductsProvider");

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(ProductDispatchContext);

  if (dispatch === undefined)
    throw new Error("useDispatch must be used within a ProductsProvider");

  return dispatch;
};

const usePaginateProducts = () => {
  const dispatch = useDispatch();

  return useLazyQuery(GET_PRODUCTS_PAGINATE, {
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.PAGINATE, data: data.paginateProducts });
    },
  });
};

const useAllProducts = () => {
  const dispatch = useDispatch();

  return useQuery(GET_PRODUCTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.ALL, data: data.products });
    },
  });
};

const useCreateProduct = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(CREATE_PRODUCT, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.ADD, data: data.createProduct });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

const useProduct = (id) => {
  const dispatch = useContext(ProductDispatchContext);

  return useQuery(
    GET_PRODUCT,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    },
    {
      onCompleted: (data) => {
        dispatch({ type: ACTION_TYPES.VIEW, data: data.area });
      },
    }
  );
};

const useUpdateProduct = (options = {}) => {
  const dispatch = useContext(ProductDispatchContext);
  const { onSuccess, onError } = options;

  return useMutation(UPDATE_PRODUCT, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateProduct });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
    refetchQueries: [
      {
        query: GET_PRODUCTS_PAGINATE,
      },
    ],
  });
};

const useDeleteProduct = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(DELETE_PRODUCT, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.REMOVE, id: data.deleteProduct?.id });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

export {
  ProductsProvider,
  useProducts,
  useCreateProduct,
  usePaginateProducts,
  useProduct,
  useUpdateProduct,
  useDeleteProduct,
  useAllProducts,
};
