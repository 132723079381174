import { gql } from "@apollo/client";

export const GET_PRODUCTS = gql`
  query getProducts {
    products {
      id
      name
    }
  }
`;

export const GET_PRODUCTS_PAGINATE = gql`
  query getProducts($first: Int! = 30, $page: Int! = 1, $client_id: String) {
    paginateProducts(first: $first, page: $page, client_id: $client_id) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        name
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query getProduct($id: ID!) {
    product(id: $id) {
      id
      name
      url
      description
      start_date
      renovation_date
      payment_method
      client_id
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($input: CreateProduct) {
    createProduct(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($input: UpdateProduct) {
    updateProduct(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`;
