export const formatCurrency = (value, prefix = "R$") => {
  value = value.replace(/[^0-9]/g, "");
  if (value.length > 3) value = value.replace(/^0+/, "");

  value = value
    .replace(/(?<!^)[a-zA-Z]/g, "") // Substitui caracteres alpha, com exceção do primeiro que faz parte do prefixo R$
    .replace(/^R\$ (\d{1,2})$/, "R$ 0,$1") // Adiciona 0, se valor for menor que 3 dígitos
    .replace(/(\d)(\d{2})$/, "$1,$2") // Separa casas decimais do restante
    .replace(/(?=(\d{3})+(\D))\B/g, "."); // Divide à cada 3 dígitos com um .
  value = `${prefix} ${value}`;
  return value;
};
