import React, { useState, useEffect } from "react";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Skeleton,
  useColorModeValue,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useAllCollaborators } from "hooks/collaborators";
import MultiSelect from "components/MultiSelect/MultiSelect";

const AreaForm = ({ defaultValues = {} }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputHover = useColorModeValue("governance.800", "governance.800");
  const textColor = useColorModeValue("gray.700", "white");

  const { data: { collaborators } = {}, loading } = useAllCollaborators();

  return (
    <>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.name}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Nome
          </FormLabel>
          <Input
            {...register("name")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.name}
          />
          {errors.name && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
        gap="24px"
        mt="24px"
      >
        <FormControl>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Colaboradores vinculados
          </FormLabel>
          {loading ? (
            <Skeleton height="20px" />
          ) : (
            <MultiSelect
              fieldName={"collaborators"}
              options={collaborators}
              defaultSelection={defaultValues?.collaborators}
            />
          )}
        </FormControl>
      </Grid>
    </>
  );
};

export default AreaForm;
