import * as yup from "yup";

export const userSchema = yup.object().shape({
  name: yup.string().required("Esse campo é obrigatório"),
  email: yup
    .string()
    .email("Insira um e-mail válido")
    .required("Esse campo é obrigatório"),
  password: yup.string().required("Esse campo é obrigatório"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Senhas devem coincidir"),
});
