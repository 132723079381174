import { Badge, Box, Button, Flex, Select, Text } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { getContrastColor } from "functions/colors";

export default function MultiSelect({
  fieldName,
  defaultSelection = [],
  options = [],
  actionOption = null,
}) {
  const { setValue } = useFormContext();

  const [selected, setSelected] = useState(
    defaultSelection.map((value) => {
      return {
        value: value?.id ?? null,
        name: value?.name ?? null,
        color: value?.color ?? null,
        disconnect: true,
      };
    }) || []
  );

  const [disconnected, setDisconnected] = useState([]);

  const [optionsArr, setOptions] = useState(
    options
      .map((option) => {
        const isSelected = !selected.some((sel) => sel?.value === option?.id);
        if (isSelected)
          return {
            name: option?.name,
            value: option?.id,
            color: option?.color || null,
          };
      })
      .filter((option) => option !== null && option !== undefined) || []
  );

  const getConnect = () => {
    const connect = [];
    const defaultIds = defaultSelection?.map((value) => value?.id) || [];

    selected?.map((sel) => {
      if (!defaultIds.includes(sel?.value)) connect.push(sel?.value);
    });

    return connect;
  };

  useEffect(() => {
    setValue(`${fieldName}.connect`, getConnect());
  }, [selected]);

  useEffect(() => {
    setValue(`${fieldName}.disconnect`, disconnected);
  }, [disconnected]);

  const isEmpty = (str) => {
    str = str.trim();
    return str === "" || str === null;
  };

  const handleSelect = (e) => {
    if (isEmpty(e.target.value) || e.target.value === "action") return;

    const newValue = {
      value: e.target.value,
      name: e.target.options[e.target.selectedIndex].text,
      color: e.target.options[e.target.selectedIndex]?.dataset.color || null,
    };

    setOptions((optionsArr) => [
      ...optionsArr.filter((option) => option?.value !== newValue?.value),
    ]);

    if (disconnected?.some((disconnect) => disconnect == newValue?.value))
      setDisconnected((disconnected) => [
        ...disconnected.filter((disconnect) => disconnect !== newValue?.value),
      ]);

    setSelected((selected) => [...selected, newValue]);
  };

  const handleRemove = (removed) => {
    if (removed.disconnect)
      setDisconnected((disconnected) => [...disconnected, removed?.value]);

    setOptions((optionsArr) => [...optionsArr, removed]);
    setSelected((selected) => [
      ...selected.filter((option) => option?.value !== removed?.value),
    ]);
  };

  return (
    <Box
      borderRadius="md"
      color="black"
      border="1px solid"
      borderColor="gray.300"
      px={4}
      minH="fit-content"
      m="12px 0"
    >
      {selected?.map((value) => (
        <Badge
          variant="subtle"
          color={value?.color ? getContrastColor(value?.color) : "#282B2A"}
          bg={value?.color || "#c6f6d5"}
          minW="50px"
          padding="10px"
          margin="10px 5px"
        >
          <Flex align="center">
            <Text fontSize="14px" mr="12px">
              {" "}
              {value?.name}
            </Text>
            <Button
              onClick={() => handleRemove(value)}
              colorScheme="red"
              size="xs"
              borderRadius="2px"
            >
              X
            </Button>
          </Flex>
        </Badge>
      ))}
      <Select
        border="none"
        focusBorderColor="none"
        value="Selecione..."
        placeholder="Selecione..."
        onChange={(e) => handleSelect(e)}
        _hover={{
          cursor: "pointer",
        }}
      >
        {actionOption && (
          <option value="action" onClick={() => actionOption?.callback()}>
            + {actionOption?.name}
          </option>
        )}
        {optionsArr?.map((option) => (
          <option value={option?.value} data-color={option?.color}>
            {option?.name}
          </option>
        ))}
      </Select>
    </Box>
  );
}
