import { useAuth, useLogout } from "hooks/auth";

// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom Icons
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import menuItems from "menu";
import Icon from "components/FontAwesomeIcons/Icon";
import TimeAgo from "javascript-time-ago";
import br from "javascript-time-ago/locale/br.json";

TimeAgo.addDefaultLocale(br);
TimeAgo.addLocale(br);

export default function HeaderLinks(props) {
  const { user } = useAuth();
  const [ logout, setLogout ] = useState(false);
  const { data: sucessfullLogout } = useLogout({
    enabled: !!logout,
  });
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  const toast = useToast();

  const [hasNewNot, setHasNewNot] = React.useState(false);

  // Chakra Color Mode
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("white", "white");

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  const settingsRef = React.useRef();

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
    >
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={menuItems}
        {...rest}
      />
      <SettingsIcon
        cursor="pointer"
        me="16px"
        ref={settingsRef}
        onClick={props.onOpen}
        color={navbarIcon}
        w="20px"
        h="20px"
      />
      <Menu>
        <MenuButton onClick={() => setHasNewNot(false)} me="16px">
          <BellIcon
            color={hasNewNot ? "red.400" : navbarIcon}
            w="20px"
            h="20px"
          />
        </MenuButton>
      </Menu>
      <NavLink to="/sistema/perfil">
        <Button
          ms="0px"
          px="0px"
          color={navbarIcon}
          variant="transparent-with-icon"
          leftIcon={
            <ProfileIcon color={navbarIcon} w="24px" h="24px" me="0px" />
          }
        >
          <Text
            fontWeight="bold"
            color={navbarIcon}
            cursor="pointer"
            mr="16px"
            display={{ sm: "none", md: "flex" }}
          >
            {user?.attributes?.name}
          </Text>
        </Button>
      </NavLink>
      <Text
        fontWeight="bold"
        color={navbarIcon}
        cursor="pointer"
        mr="16px"
        display={{ sm: "none", md: "flex" }}
        onClick={() => setLogout(true)}
      >
        <Icon icon="logout" margin="8" w="24px" h="24px" />
        Sair
      </Text>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
