import React from "react";
import { NavLink, useHistory } from "react-router-dom";

import {
  Button,
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ProductForm from "./ProductForm";
import { productSchema } from "./yupSchema";
import { useCreateProduct } from "hooks/products";
import dateFormat from "functions/dateFormat";

import { useParams } from "react-router-dom";

const CreateProduct = ({ isOpen, closeModal }) => {
  const toast = useToast();
  const { id: clientId } = useParams();

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(productSchema),
  });

  const { handleSubmit, reset } = methods;

  const [createProduct, { data, loading, error }] = useCreateProduct({
    onSuccess: (data) => {
      reset();
      toast({
        title: "Produto criado com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      }),
        closeModal();
    },
    onError: (err) => {
      console.log(err);
      toast({
        title: "Ocorreu um erro",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const getDate = (value) => {
    const splitted = value.split(" ");
    const date = splitted[0].split("/");
    const dateTime = new Date(date[2], date[1] - 1, date[0]);

    return `${dateFormat(dateTime, "Y-MM-dd")}`;
  };

  const handleDataBeforeSubmit = (data) => {
    if (data?.start_date) {
      data.start_date = getDate(data.start_date);
    }

    if (data?.renovation_date) {
      data.renovation_date = getDate(data.renovation_date);
    }

    return data;
  };

  const onSubmit = (data) => {
    data = handleDataBeforeSubmit(data);
    const variables = {
      input: {
        client_id: clientId,
        ...data,
      },
    };

    createProduct({ variables: variables });
  };

  return (
    <Modal
      isCentered
      onClose={() => closeModal()}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <FormProvider {...methods}>
        <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Produto</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="column" w="100%">
                <ProductForm />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} variant="ghost" onClick={() => closeModal()}>
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="no-hover"
                bg="governance.700"
                alignSelf="flex-end"
                w="100px"
                h="35px"
              >
                <Text fontSize="xs" color="#fff" fontWeight="bold">
                  Criar
                </Text>
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CreateProduct;
