export const isColorDark = (color) => {
  // Converte a cor para o formato RGB
  const rgb = color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
  const r = parseInt(rgb[1], 16);
  const g = parseInt(rgb[2], 16);
  const b = parseInt(rgb[3], 16);

  // Calcula a luminosidade da cor usando a fórmula YIQ
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Define um valor de limiar para distinguir cores claras e escuras
  const threshold = 128;

  // Retorna true se a cor for escura e false se for clara
  return yiq < threshold;
};

export const getContrastColor = (color) => {
  return isColorDark(color) ? "#E3FFF2" : "#282B2A";
};
