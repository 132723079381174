import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Skeleton,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useOutgoing } from "hooks/outgoings";
import { CheckCircleIcon } from "@chakra-ui/icons";
import dateFormat from "functions/dateFormat";
import { formatCurrency } from "functions/currency";
import { getContrastColor } from "functions/colors";

const ViewOutgoing = () => {
  const { id } = useParams();
  const { data: { outgoing } = {}, loading } = useOutgoing(id);

  const buttonBg = useColorModeValue("governance.700", "governance.700");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <Card w={{ md: "100%", lg: "50%" }}>
        <CardHeader mb="40px">
          <Flex direction="row" w="100%" justify="space-between">
            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="3px">
              Gasto
            </Text>
            <Flex>
              <NavLink to={`/sistema/financeiro/saidas/${id}/editar`}>
                <Button
                  backgroundColor={buttonBg}
                  color="white"
                  _hover={{ bg: "governance.800" }}
                  mr="12px"
                  padding="0 24px"
                >
                  Editar
                </Button>
              </NavLink>
              <NavLink to="/sistema/financeiro/saidas">
                <Button>Voltar</Button>
              </NavLink>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <VStack spacing="24px">
              <FormControl>
                <FormLabel color="gray.600" fontSize="xs">
                  Área
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Flex align="center">
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mr="12px"
                    >
                      {outgoing?.area?.name || null}
                    </Text>
                  </Flex>
                </Skeleton>
              </FormControl>
              <FormControl>
                <FormLabel color="gray.600" fontSize="xs">
                  Valor
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Flex align="center">
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mr="12px"
                    >
                      {outgoing?.value ? formatCurrency(outgoing?.value) : null}
                    </Text>
                  </Flex>
                </Skeleton>
              </FormControl>
              <Grid templateColumns="repeat(2, 1fr)" w="100%">
                {outgoing?.payed_at && (
                  <FormControl>
                    <FormLabel color="gray.600" fontSize="xs">
                      Data de pagamento
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!loading}>
                      <Flex align="center">
                        <Text
                          color={textColor}
                          fontSize="lg"
                          fontWeight="bold"
                          mr="12px"
                        >
                          {outgoing?.payed_at
                            ? dateFormat(outgoing.payed_at)
                            : null}
                        </Text>
                      </Flex>
                    </Skeleton>
                  </FormControl>
                )}

                {outgoing?.competence && (
                  <FormControl>
                    <FormLabel color="gray.600" fontSize="xs">
                      Competência
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!loading}>
                      <Flex align="center">
                        <Text
                          color={textColor}
                          fontSize="lg"
                          fontWeight="bold"
                          mr="12px"
                        >
                          {outgoing?.competence
                            ? dateFormat(outgoing.competence)
                                .split("/")
                                .slice(1)
                                .join("/")
                            : null}
                        </Text>
                      </Flex>
                    </Skeleton>
                  </FormControl>
                )}
              </Grid>

              {outgoing?.description && (
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Descrição
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!loading}>
                    <Flex align="center">
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mr="12px"
                      >
                        {outgoing?.description || null}
                      </Text>
                    </Flex>
                  </Skeleton>
                </FormControl>
              )}

              {outgoing?.tags && (
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Tags
                  </FormLabel>
                  {outgoing?.tags?.map((tag) => (
                    <Badge
                      variant="subtle"
                      color={
                        tag?.color ? getContrastColor(tag?.color) : "#22543D"
                      }
                      bg={tag?.color || "#c6f6d5"}
                      minW="50px"
                      padding="10px"
                      margin="5px"
                    >
                      <Text fontSize="14px"> {tag?.name}</Text>
                    </Badge>
                  ))}
                </FormControl>
              )}
            </VStack>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default ViewOutgoing;
