import React from "react";
import { createContext, useContext, useReducer } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  GET_USERS_PAGINATE,
  GET_USER,
  CREATE_USER,
  DELETE_USER,
} from "graphql/users";
import { ACTION_TYPES, reducers } from "./reducers";

const UsersStateContext = createContext();
const UsersDispatchContext = createContext();

const UsersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {
    rows: [],
    first: 15,
    page: 1,
  });

  const { data, loading, error } = useQuery(GET_USERS_PAGINATE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.PAGINATE, data: data.paginateUsers });
    },
  });

  return (
    <UsersStateContext.Provider value={{ data, loading, error, ...state }}>
      <UsersDispatchContext.Provider value={dispatch}>
        {children}
      </UsersDispatchContext.Provider>
    </UsersStateContext.Provider>
  );
};

const useUsers = () => {
  const context = useContext(UsersStateContext);

  if (!context)
    throw new Error("useUsers must be used within an UsersProvider");

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(UsersDispatchContext);

  if (dispatch === undefined)
    throw new Error("useDispatch must be used within a UsersProvider");

  return dispatch;
};

const usePaginateUsers = () => {
  const dispatch = useDispatch();

  return useLazyQuery(GET_USERS_PAGINATE, {
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.PAGINATE, data: data.paginateUsers });
    },
  });
};

const useCreateUser = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(CREATE_USER, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.ADD, data: data.createUser });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

const useUser = (id) => {
  const dispatch = useContext(UsersDispatchContext);

  return useQuery(
    GET_USER,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    },
    {
      onCompleted: (data) => {
        dispatch({ type: ACTION_TYPES.VIEW, data: data.user });
      },
    }
  );
};

const useUpdateUser = (options = {}) => {
  const dispatch = useContext(UsersDispatchContext);
  const { onSuccess, onError } = options;

  return useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateUser });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
    refetchQueries: [
      {
        query: GET_USERS_PAGINATE,
      },
    ],
  });
};

const useDeleteUser = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(DELETE_USER, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.REMOVE, data: data?.deleteUser?.id });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

export {
  UsersProvider,
  useUsers,
  useCreateUser,
  usePaginateUsers,
  useUser,
  useUpdateUser,
  useDeleteUser,
};
