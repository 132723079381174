import React from "react";

import {
  Button,
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ContactForm from "./ContactForm";
import { contactSchema } from "./yupSchema";
import { useCreateContact } from "hooks/contacts";

import { useParams } from "react-router-dom";

const CreateContact = ({ isOpen, closeModal }) => {
  const toast = useToast();
  const { id: clientId } = useParams();

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(contactSchema),
  });

  const { handleSubmit, reset } = methods;

  const [createContact, { data, loading, error }] = useCreateContact({
    onSuccess: (data) => {
      reset();
      toast({
        title: "Contato criado com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      }),
        closeModal();
    },
    onError: (err) => {
      console.log(err);
      toast({
        title: "Ocorreu um erro",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const onSubmit = (data) => {
    const variables = {
      input: {
        client_id: clientId,
        ...data,
      },
    };

    createContact({ variables: variables });
  };

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Modal
      isCentered
      onClose={() => closeModal()}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <FormProvider {...methods}>
        <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Contato</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="column" w="100%">
                <ContactForm />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} variant="ghost" onClick={() => closeModal()}>
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="no-hover"
                bg="governance.700"
                alignSelf="flex-end"
                w="100px"
                h="35px"
              >
                <Text fontSize="xs" color="#fff" fontWeight="bold">
                  Criar
                </Text>
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CreateContact;
