import { UsersProvider as Provider } from 'hooks/users/index'
import { ApolloQueryProvider } from 'services/apollo'

const UsersProvider = ({ children }) => {
	return (
		<ApolloQueryProvider>
			<Provider>{children}</Provider>
		</ApolloQueryProvider>
	)
}

export default UsersProvider
