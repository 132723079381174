import { AreasProvider as Provider } from 'hooks/areas/index'
import { CollaboratorsProvider } from 'hooks/collaborators'
import { ApolloQueryProvider } from 'services/apollo'

const AreasProvider = ({ children }) => {
	return (
		<ApolloQueryProvider>
			<CollaboratorsProvider>
				<Provider>{children}</Provider>
			</CollaboratorsProvider>
		</ApolloQueryProvider>
	)
}

export default AreasProvider
