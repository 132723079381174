import { CollaboratorsProvider as Provider } from "hooks/collaborators/index";
import { AreasProvider } from "hooks/areas";
import { ApolloQueryProvider } from "services/apollo";

const CollaboratorsProvider = ({ children }) => {
  return (
    <ApolloQueryProvider>
      <AreasProvider>
        <Provider>{children}</Provider>
      </AreasProvider>
    </ApolloQueryProvider>
  );
};

export default CollaboratorsProvider;
