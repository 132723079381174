import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Skeleton,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

import { NavLink } from "react-router-dom";

export default ({ client, loading }) => {
  const buttonBg = useColorModeValue("governance.700", "governance.700");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card w={{ md: "100%", lg: "50%" }}>
      <CardHeader mb="40px">
        <Flex direction="row" w="100%" justify="space-between">
          <Text color={textColor} fontSize="lg" fontWeight="bold" mb="3px">
            Cliente
          </Text>
          <Flex>
            <NavLink
              to={`/sistema/administracao/clientes/${client?.id}/editar`}
            >
              <Button
                backgroundColor={buttonBg}
                color="white"
                _hover={{ bg: "governance.800" }}
                mr="12px"
                padding="0 24px"
              >
                Editar
              </Button>
            </NavLink>
            <NavLink to="/sistema/administracao/clientes">
              <Button>Voltar</Button>
            </NavLink>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody>
        <Flex direction="column" w="100%">
          <VStack spacing="24px">
            <FormControl>
              <FormLabel color="gray.600" fontSize="xs">
                Nome
              </FormLabel>
              <Skeleton height={"25px"} isLoaded={!loading}>
                <Flex align="center">
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mr="12px"
                  >
                    {client?.name || null}
                  </Text>
                </Flex>
              </Skeleton>
            </FormControl>

            <FormControl>
              <FormLabel color="gray.600" fontSize="xs">
                CNPJ
              </FormLabel>
              <Skeleton height={"25px"} isLoaded={!loading}>
                <Flex align="center">
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mr="12px"
                  >
                    {client?.cnpj || null}
                  </Text>
                </Flex>
              </Skeleton>
            </FormControl>
          </VStack>
        </Flex>
      </CardBody>
    </Card>
  );
};
