import React from "react";
import { createContext, useContext, useReducer } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  GET_COLLABORATORS_PAGINATE,
  GET_COLLABORATOR,
  CREATE_COLLABORATOR,
  UPDATE_COLLABORATOR,
} from "graphql/collaborators";
import { ACTION_TYPES, reducers } from "./reducers";
import { DELETE_COLLABORATOR } from "graphql/collaborators";
import { GET_COLLABORATORS } from "graphql/collaborators";

const CollaboratorsStateContext = createContext();
const CollaboratorsDispatchContext = createContext();

const CollaboratorsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {
    rows: [],
    first: 15,
    page: 1,
  });

  const { data, loading, error } = useQuery(GET_COLLABORATORS_PAGINATE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.LIST, data: data.paginateCollaborators });
    },
  });

  return (
    <CollaboratorsStateContext.Provider
      value={{ data, loading, error, ...state }}
    >
      <CollaboratorsDispatchContext.Provider value={dispatch}>
        {children}
      </CollaboratorsDispatchContext.Provider>
    </CollaboratorsStateContext.Provider>
  );
};

const useCollaborators = () => {
  const context = useContext(CollaboratorsStateContext);

  if (!context)
    throw new Error(
      "useCollaborators must be used within an CollaboratorsProvider"
    );

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(CollaboratorsDispatchContext);

  if (dispatch === undefined)
    throw new Error("useDispatch must be used within a CollaboratorsProvider");

  return dispatch;
};

const useAllCollaborators = () => {
  const dispatch = useDispatch();

  return useQuery(GET_COLLABORATORS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.ALL, data: data.collaborators });
    },
  });
};

const usePaginateCollaborators = () => {
  const dispatch = useDispatch();

  return useLazyQuery(GET_COLLABORATORS_PAGINATE, {
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.LIST, data: data.paginateCollaborators });
    },
  });
};

const useCreateCollaborator = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(CREATE_COLLABORATOR, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.ADD, data: data.createCollaborator });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

const useCollaborator = (id) => {
  const dispatch = useContext(CollaboratorsDispatchContext);

  return useQuery(
    GET_COLLABORATOR,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    },
    {
      onCompleted: (data) => {
        dispatch({ type: ACTION_TYPES.VIEW, data: data.collaborator });
      },
    }
  );
};

const useUpdateCollaborator = (options = {}) => {
  const dispatch = useContext(CollaboratorsDispatchContext);
  const { onSuccess, onError } = options;

  return useMutation(UPDATE_COLLABORATOR, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateCollaborator });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
    refetchQueries: [
      {
        query: GET_COLLABORATORS_PAGINATE,
      },
    ],
  });
};

const useDeleteCollaborator = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(DELETE_COLLABORATOR, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.REMOVE, id: data.deleteCollaborator?.id });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

export {
  CollaboratorsProvider,
  useCollaborators,
  useAllCollaborators,
  useCreateCollaborator,
  usePaginateCollaborators,
  useCollaborator,
  useUpdateCollaborator,
  useDeleteCollaborator,
};
