import React from "react";

import { useParams } from "react-router-dom";
import { Flex, useColorModeValue } from "@chakra-ui/react";
import { useClient } from "hooks/clients";

import ClientCard from "./components/ClientCard";
import ProductsCard from "./components/ProductsCard";
import ContactsCard from "./components/ContactsCard";

const ViewClient = () => {
  const { id } = useParams();
  const { data: { client } = {}, loading } = useClient(id);

  const buttonBg = useColorModeValue("governance.700", "governance.700");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      gap="24px"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <ClientCard client={client} loading={loading} />

      <ProductsCard client={client} loading={loading} />

      <ContactsCard client={client} loading={loading} />
    </Flex>
  );
};

export default ViewClient;
