export const tagPalettes = [
    { name: "Vermelho Sangue", value: "#B71C1C" },
    { name: "Vermelho Escarlate", value: "#C62828" },
    { name: "Vermelho Escuro", value: "#D32F2F" },
    { name: "Vermelho Profundo", value: "#E53935" },
    { name: "Vermelho Cereja", value: "#F44336" },
    { name: "Vermelho Coral", value: "#EF5350" },
    { name: "Vermelho Fogo", value: "#FF5252" },
  
    { name: "Laranja Escuro", value: "#E65100" },
    { name: "Laranja Queimado", value: "#EF6C00" },
    { name: "Laranja Telha", value: "#F57C00" },
    { name: "Laranja Abóbora", value: "#FF5722" },
    { name: "Laranja Coral", value: "#FF7043" },
    { name: "Laranja Pêssego", value: "#FF8A65" },
    { name: "Laranja Cenoura", value: "#FFAB91" },
  
    { name: "Amarelo Mostarda", value: "#FFA000" },
    { name: "Amarelo Escuro", value: "#FFB300" },
    { name: "Amarelo Claro", value: "#FFC107" },
    { name: "Amarelo Canário", value: "#FFEB3B" },
    { name: "Amarelo Limão", value: "#CDDC39" },
    { name: "Amarelo Pastel", value: "#FFF59D" },
    { name: "Amarelo Pálido", value: "#FFF176" },
  
    { name: "Azul Petróleo", value: "#004D40" },
    { name: "Azul Escuro", value: "#0D47A1" },
    { name: "Azul Profundo", value: "#1A237E" },
    { name: "Azul Índigo", value: "#283593" },
    { name: "Azul Rocha", value: "#303F9F" },
    { name: "Azul Elétrico", value: "#3949AB" },
    { name: "Azul Violeta", value: "#5C6BC0" },
  
    { name: "Verde Floresta", value: "#1B5E20" },
    { name: "Verde Pinheiro", value: "#2E7D32" },
    { name: "Verde Escuro", value: "#388E3C" },
    { name: "Verde Floresta", value: "#43A047" },
    { name: "Verde Musgo", value: "#4CAF50" },
    { name: "Verde Limão", value: "#7CB342" },
    { name: "Verde Amarelado", value: "#9CCC65" },
  
    { name: "Roxo Ameixa", value: "#4A148C" },
    { name: "Roxo Escuro", value: "#6A1B9A" },
    { name: "Roxo Profundo", value: "#7B1FA2" },
    { name: "Roxo Ametista", value: "#9C27B0" },
    { name: "Roxo Púrpura", value: "#AB47BC" },
    { name: "Roxo Vinho", value: "#BA68C8" },
    { name: "Roxo Lilás", value: "#CE93D8" },
  
    { name: "Rosa Claro", value: "#E91E63" },
    { name: "Rosa Chiclete", value: "#F06292" },
    { name: "Rosa Pink", value: "#EC407A" },
    { name: "Rosa Fúcsia", value: "#D81B60" },
    { name: "Rosa Púrpura", value: "#9C27B0" },
    { name: "Rosa Lavanda", value: "#BA68C8" },
    { name: "Rosa Pastel", value: "#F48FB1" },
  ];
  