import { gql } from "@apollo/client";

export const GET_CLIENTS = gql`
  query getClients {
    clients {
      id
      name
      products {
        id
        name
      }
    }
  }
`;

export const GET_CLIENTS_PAGINATE = gql`
  query getClients($first: Int! = 30, $page: Int! = 1) {
    paginateClients(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        perPage
        lastItem
        total
      }
      data {
        id
        name
        cnpj
      }
    }
  }
`;

export const GET_CLIENT = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      id
      name
      cnpj
      products {
        id
        name
      }
      contacts {
        id
        name
      }
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation createClient($input: CreateClient) {
    createClient(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient($input: UpdateClient) {
    updateClient(input: $input) {
      id
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation deleteClient($id: ID!) {
    deleteClient(id: $id) {
      id
    }
  }
`;
