import { OutgoingsProvider as Provider } from "hooks/outgoings/index";
import { AreasProvider } from "hooks/areas";
import { TagsProvider } from "hooks/tags";
import { ApolloQueryProvider } from "services/apollo";

const OutgoingsProvider = ({ children }) => {
  return (
    <ApolloQueryProvider>
      <AreasProvider>
        <TagsProvider>
          <Provider>{children}</Provider>
        </TagsProvider>
      </AreasProvider>
    </ApolloQueryProvider>
  );
};

export default OutgoingsProvider;
