import React from "react";

import { NavLink, useHistory, useParams } from "react-router-dom";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { productSchema } from "./yupSchema";

import {
  Button,
  Flex,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ProductForm from "./ProductForm";
import { useUpdateProduct } from "hooks/products";
import { useProduct } from "hooks/products";
import dateFormat from "functions/dateFormat";

const EditProduct = ({ id, isOpen, closeModal }) => {
  const { data: { product } = {}, loading } = useProduct(id);

  const { id: clientId } = useParams();

  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(productSchema),
  });
  const toast = useToast();

  const { reset, handleSubmit } = methods;

  const [updateProduct, { loading: loadingUpdate, error }] = useUpdateProduct({
    onSuccess: (data) => {
      reset();
      toast({
        title: "Produto atualizado com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      }),
        closeModal();
    },
    onError: (err) => {
      console.log(err);
      toast({
        title: "Ocorreu um erro",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const getDate = (value) => {
    const splitted = value.split(" ");
    const date = splitted[0].split("/");
    const dateTime = new Date(date[2], date[1] - 1, date[0]);

    return `${dateFormat(dateTime, "Y-MM-dd")}`;
  };

  const handleDataBeforeSubmit = (data) => {
    if (data?.start_date) {
      data.start_date = getDate(data.start_date);
    }

    if (data?.renovation_date) {
      data.renovation_date = getDate(data.renovation_date);
    }

    data.client_id = clientId;

    return data;
  };

  const onSubmit = (data) => {
    data = handleDataBeforeSubmit(data);
    const variables = {
      input: {
        id: id,
        ...data,
      },
    };

    updateProduct({ variables: variables });
  };

  return (
    <Modal
      isCentered
      onClose={() => closeModal()}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <FormProvider {...methods}>
        <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Editar Produto</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="column" w="100%">
                {!loading && <ProductForm defaultValues={product} />}
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button
                mr={3}
                variant="ghost"
                onClick={() => {
                  reset();
                  closeModal();
                }}
              >
                Cancelar
              </Button>
              <Button
                isLoading={loading}
                type="submit"
                variant="no-hover"
                bg="governance.700"
                alignSelf="flex-end"
                w="100px"
                h="35px"
              >
                <Text fontSize="xs" color="#fff" fontWeight="bold">
                  Atualizar
                </Text>
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditProduct;
