import { useEffect, useState } from "react";
import AsyncTable from "components/Tables/AsyncTable";
import { useIncomings } from "hooks/incomings";
import { usePaginateIncomings } from "hooks/incomings";
import DeleteIncoming from "./DeleteIncoming";
import dateFormat from "functions/dateFormat";
import { formatCurrency } from "functions/currency";
import IncomingsFilters from "./components/Filters";
import { competenceFormat } from "functions/date";
import { competenceToISO8601 } from "functions/date";

const columns = [
  {
    Header: "Produto",
    accessor: "product.name",
  },
  {
    Header: "Competência",
    accessor: "competence",
    Cell: (props) => (
      <div>
        {props.value
          ? dateFormat(props.value).split("/").slice(1).join("/")
          : ""}
      </div>
    ),
  },
  {
    Header: "Valor",
    accessor: "value",
    Cell: (props) => (
      <div>{props.value ? formatCurrency(props.value) : "R$ 0,00"}</div>
    ),
  },
];

const IncomingsTable = () => {
  const { loading, rows = [{}], total, perPage, currentPage } = useIncomings();

  const [paginateIncomings, { loading: reloading }] = usePaginateIncomings();
  const [filters, setFilters] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [incoming, setIncoming] = useState({});

  const fetchData = ({ pageSize, pageIndex, search }) => {
    if (pageSize != perPage) pageIndex = 1;
    paginateIncomings({
      variables: {
        first: pageSize || 30,
        page: pageIndex || 1,
        ...(filters?.competence
          ? { competence: competenceToISO8601(filters.competence) }
          : null),
        ...(filters?.product ? { product_id: filters.product } : null),
      },
    });
  };

  useEffect(() => {
    fetchData(1, 30);
  }, [filters]);

  const handleDelete = (id) => {
    setIncoming({ id: id });
    setDeleteModal(true);
  };

  return (
    <>
      <IncomingsFilters filters={filters} setFilter={setFilters} />
      <DeleteIncoming
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(false)}
        incoming={incoming}
      />
      <AsyncTable
        isLoading={loading || reloading}
        fetchData={fetchData}
        data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
        columns={columns}
        total={total}
        asyncPage={currentPage}
        baseRoute="entradas"
        removeAction={(id) => handleDelete(id)}
        linkRow
      />
    </>
  );
};

export default IncomingsTable;
