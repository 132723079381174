import { createContext, useContext, useReducer, useState } from "react";
import { useQuery as useApolloQuery } from "@apollo/client";
import { useQuery } from "react-query";

import { ACTION_TYPES, reducers } from "./reducers";
import { ME } from "graphql/users";
import { API } from "services/api";
import { isEmpty } from "lodash";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const AuthProvider = ({ children, user }) => {
  const [state, dispatch] = useReducer(reducers, {
    user: user,
  });

  const [loading, setLoading] = useState(true);

  useQuery({
    queryKey: ["me"],
    queryFn: async () => {
      const { data } = await API.post(
        process.env.REACT_APP_API_URL + "/api/auth/me"
      );

      return data;
    },
    onSuccess: (data) => {
      setLoading(false);
      return dispatch({ type: ACTION_TYPES.ME, data: data });
    },
    onError: (err) => {
      setLoading(false);
    },
  });

  return (
    <AuthStateContext.Provider value={{ isLoading: loading, user: state.user }}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthStateContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

const useRegister = (formData = {}, options = {}) => {
  return useQuery({
    queryKey: ["register"],
    queryFn: async () => {
      const { data } = await API.post(
        process.env.REACT_APP_API_URL + "/api/auth/register",
        {
          name: formData?.name,
          email: formData?.email,
          password: formData?.password,
        }
      );

      return data;
    },
    onSuccess: (data) => {
      window.localStorage.setItem("governance_token", data?.access_token);
      location.reload();
    },
    onError: (err) => {
      console.log("Falha ao registrar!");
    },
    enabled: options?.enabled || false,
  });
};

const useLogin = (formData = {}, options = {}) => {
  return useQuery({
    queryKey: ["login"],
    queryFn: async () => {
      const { data } = await API.post(
        process.env.REACT_APP_API_URL + "/api/auth/login",
        {
          email: formData?.email,
          password: formData?.password,
        }
      );

      return data;
    },
    onSuccess: (data) => {
      window.localStorage.setItem("governance_token", data?.access_token);
      location.reload();
    },
    onError: (err) => {
      console.log("Falha ao realizar login!");
    },
    enabled: options?.enabled || false,
  });
};

const useLogout = (options = {}) => {
  return useQuery({
    queryKey: ["logout"],
    queryFn: async () => {
      const { data } = API.post(
        process.env.REACT_APP_API_URL + "/api/auth/logout"
      );

      return data;
    },
    onSuccess: (data) => {
      window.localStorage.removeItem("governance_token");
      location.reload();
    },
    onError: (err) => {
      console.log("Falha ao realizar logout!");
    },
    enabled: options?.enabled || false,
  });
};

export { AuthProvider, useAuth, useRegister, useLogin, useLogout };
