import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
  Box,
  Grid,
  Flex,
} from "@chakra-ui/react";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

import { FormProvider, useForm } from "react-hook-form";
import { useCreateTag } from "hooks/tags";
import { tagPalettes } from "variables/palettes";
import { validateHex } from "variables/regex";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Esse campo é obrigatório"),
  color: yup
    .string()
    .required("Esse campo é obrigatório")
    .matches(validateHex, "Insira um código hexadecimal válido. Ex.: #5878ED"),
});

export default function TagModal({ onClose, defaultValues = {} }) {
  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    register: registerTagField,
    handleSubmit: handleSubmitTag,
    reset: resetTagForm,
    formState: { errors: tagErrors },
    getValues: getTagField,
    setValue: setTagField,
  } = methods;

  const toast = useToast();

  const [createTag, { data, loading, error }] = useCreateTag({
    onSuccess: (data) => {
      resetTagForm();
      toast({
        title: "Tag criada com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      }),
        onClose();
    },
    onError: (err) => {
      console.log(err);
      toast({
        title: "Ocorreu um erro",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const onSubmitTag = (e, data) => {
    e.preventDefault();
    e.stopPropagation();

    const variables = {
      input: {
        name: getTagField("name"),
        color: getTagField("color"),
      },
    };

    createTag({ variables: variables });
  };

  const textColor = useColorModeValue("gray.700", "white");
  const inputHover = useColorModeValue("governance.800", "governance.800");

  return (
    <>
      <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar Tag</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methods}>
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => handleSubmitTag(onSubmitTag(e))}
              >
                <VStack spacing="24px">
                  <FormControl isInvalid={tagErrors.name}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Nome
                    </FormLabel>
                    <Input
                      {...registerTagField("name")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                      defaultValue={defaultValues?.name}
                    />
                    {tagErrors.name && (
                      <FormErrorMessage>
                        {tagErrors.name.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={tagErrors.color}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Cor
                    </FormLabel>
                    <Input
                      {...registerTagField("color")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                      placeholder="#c6f6d5"
                      defaultValue={defaultValues?.color}
                    />
                    {tagErrors.color && (
                      <FormErrorMessage>
                        {tagErrors.color.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <Grid templateColumns="repeat(7, 1fr)" gap="12px">
                    {tagPalettes.map((color) => (
                      <Box
                        backgroundColor={color.value}
                        width="30px"
                        height="25px"
                        borderRadius="5px"
                        _hover={{
                          cursor: "pointer",
                          opacity: "0.75",
                          padding: "14px",
                        }}
                        transition=".5s"
                        onClick={() => setTagField("color", color.value)}
                      />
                    ))}
                  </Grid>
                </VStack>
                <Flex justify="end" mt="24px">
                  <Button variant="ghost" onClick={onClose}>
                    Cancelar
                  </Button>
                  <Button type="submit" colorScheme="green" ml={3}>
                    Criar
                  </Button>
                </Flex>
              </form>
            </FormProvider>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
