import React from 'react'

import AuthProvider from "./authProvider";
import { QueryProvider } from "./queryClient";

import "../assets/css/amplify-styles-copy.css";

const AppProvider = (props) => {
    const { signOut, user, children } = props;

    return (
        <QueryProvider>
            <AuthProvider>
                {children}
            </AuthProvider>
        </QueryProvider>
    )
}

export default AppProvider