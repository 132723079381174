import React from "react";
import { createContext, useContext, useReducer } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  GET_AREAS_PAGINATE,
  GET_AREA,
  CREATE_AREA,
  UPDATE_AREA,
  DELETE_AREA,
} from "graphql/areas";
import { ACTION_TYPES, reducers } from "./reducers";
import { GET_AREAS } from "graphql/areas";

const AreasStateContext = createContext();
const AreaDispatchContext = createContext();

const AreasProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {
    rows: [],
    first: 15,
    page: 1,
  });

  const { data, loading, error } = useQuery(GET_AREAS_PAGINATE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.PAGINATE, data: data.paginateAreas });
    },
  });

  return (
    <AreasStateContext.Provider value={{ data, loading, error, ...state }}>
      <AreaDispatchContext.Provider value={dispatch}>
        {children}
      </AreaDispatchContext.Provider>
    </AreasStateContext.Provider>
  );
};

const useAreas = () => {
  const context = useContext(AreasStateContext);

  if (!context)
    throw new Error("useAreas must be used within an AreasProvider");

  return context;
};

const useDispatch = () => {
  const dispatch = useContext(AreaDispatchContext);

  if (dispatch === undefined)
    throw new Error("useDispatch must be used within a AreasProvider");

  return dispatch;
};

const usePaginateAreas = () => {
  const dispatch = useDispatch();

  return useLazyQuery(GET_AREAS_PAGINATE, {
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.PAGINATE, data: data.paginateAreas });
    },
  });
};

const useAllAreas = () => {
  const dispatch = useDispatch();

  return useQuery(GET_AREAS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({ type: ACTION_TYPES.ALL, data: data.areas });
    },
  });
};

const useCreateArea = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(CREATE_AREA, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.ADD, data: data.createArea });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

const useArea = (id) => {
  const dispatch = useContext(AreaDispatchContext);

  return useQuery(
    GET_AREA,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    },
    {
      onCompleted: (data) => {
        dispatch({ type: ACTION_TYPES.VIEW, data: data.area });
      },
    }
  );
};

const useUpdateArea = (options = {}) => {
  const dispatch = useContext(AreaDispatchContext);
  const { onSuccess, onError } = options;

  return useMutation(UPDATE_AREA, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateArea });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
    refetchQueries: [
      {
        query: GET_AREAS_PAGINATE,
      },
    ],
  });
};

const useDeleteArea = (options = {}) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options;

  return useMutation(DELETE_AREA, {
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data);
      dispatch({ type: ACTION_TYPES.REMOVE, id: data.deleteArea?.id });
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });
};

export {
  AreasProvider,
  useAreas,
  useCreateArea,
  usePaginateAreas,
  useArea,
  useUpdateArea,
  useDeleteArea,
  useAllAreas,
};
