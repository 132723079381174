const ACTION_TYPES = {
  ALL: "ALL",
  PAGINATE: "PAGINATE",
  SELECT: "SELECT",
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
};

const reducers = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.ALL: {
      return {
        ...state,
        contacts: action.data,
      };
    }
    case ACTION_TYPES.PAGINATE: {
      return {
        ...state,
        rows: action.data.data,
        page: action.data.paginatorInfo.currentPage,
        perPage: action.data.paginatorInfo.perPage,
        total: action.data.paginatorInfo.total,
        currentPage: action.data.paginatorInfo.currentPage,
      };
    }
    case ACTION_TYPES.ADD: {
      return {
        ...state,
        rows: [...state.rows, action.data],
      };
    }
    case ACTION_TYPES.VIEW: {
      return {
        ...state,
        contacts: action.data.contact,
      };
    }
    case ACTION_TYPES.UPDATE: {
      return {
        ...state,
        contacts:
          state?.contacts?.length > 0
            ? state.contacts.map((item) =>
                item.id === action.data.id ? action.data : item
              )
            : [],
        rows:
          state?.rows?.length > 0
            ? state.rows.map((item) =>
                item.id === action.data.id ? action.data : item
              )
            : [],
      };
    }
    case ACTION_TYPES.REMOVE: {
      return {
        ...state,
        rows: state?.rows?.filter(({ id }) => id !== action.id),
      };
    }
    default: {
      return state;
    }
  }
};

export { reducers, ACTION_TYPES };
