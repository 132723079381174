import { validateDate, validateUrl } from "variables/regex";
import * as yup from "yup";

export const productSchema = yup.object().shape({
  name: yup.string().required("Esse campo é obrigatório"),
  url: yup.string().when({
    is: (value) => value != null && value !== undefined && value !== "",
    then: yup.string().matches(validateUrl, "Insira um link válido"),
    otherwise: yup.string(),
  }),
  description: yup.string().max(255, "Máximo de 255 caracteres"),
  start_date: yup.string().when({
    is: (value) => value != null && value !== undefined && value !== "",
    then: yup.string().matches(validateDate, "Insira uma data válida"),
    otherwise: yup.string(),
  }),
  renovation_date: yup.string().when({
    is: (value) => value != null && value !== undefined && value !== "",
    then: yup.string().matches(validateDate, "Insira uma data válida"),
    otherwise: yup.string(),
  }),
  payment_method: yup.string(),
});

// .when("url", {
//   is: (val) => val !== null && val !== undefined && val !== "",
//   then: yup.string().matches(validateUrl, "Insira um link válido"),
//   otherwise: yup.string(),
// })
