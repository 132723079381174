import * as yup from "yup";
import { cpf as cpfValidator } from "cpf-cnpj-validator";

export const collaboratorSchema = yup.object().shape({
  name: yup.string().required("Esse campo é obrigatório"),
  cpf: yup
    .string()
    .required("Esse campo é obrigatório")
    .test("cpf-valid", "O CPF informado não é válido", function (field) {
      return cpfValidator.isValid(field);
    }),
  phone: yup.string().required("Esse campo é obrigatório"),
  email: yup.string().email("Insira um e-mail válido"),
  purpose: yup.string(),
  type: yup
    .string()
    .oneOf(
      ["internal", "external"],
      "Esse campo só aceita os valores 'Interno' ou 'Externo'"
    ),
  journey: yup
    .string()
    .oneOf(
      ["continuous", "intermittent"],
      "Esse campo só aceita os valores  'Contínua' ou 'Intermitente'"
    ),
  payment_method: yup
    .string()
    .oneOf(
      ["account", "pix"],
      "Esse campo só aceita os valores 'Conta' ou 'Pix'"
    ),
  bank: yup.string(),
  agency: yup.string(),
  account_number: yup.string(),
  pix_key: yup.string(),
});
