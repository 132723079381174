const ACTION_TYPES = {
  ALL: "ALL",
  PAGINATE: "PAGINATE",
  SELECT: "SELECT",
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
};

const reducers = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.ALL: {
      return {
        ...state,
        tags: action.data,
      };
    }
    case ACTION_TYPES.ADD: {
      return {
        ...state,
        tags: [...state.tags, action.data],
      };
    }
    case ACTION_TYPES.VIEW: {
      return {
        ...state,
        tags: action.data.tag,
      };
    }
    case ACTION_TYPES.REMOVE: {
      return {
        ...state,
        rows: state?.rows?.filter(({ id }) => id !== action.id),
      };
    }
    default: {
      return state;
    }
  }
};

export { reducers, ACTION_TYPES };
