import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Skeleton,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useIncoming } from "hooks/incomings";
import dateFormat from "functions/dateFormat";
import { formatCurrency } from "functions/currency";
import { FaDownload } from "react-icons/fa";
import { useGetImport } from "hooks/incomings";

const ViewIncoming = () => {
  const { id } = useParams();
  const toast = useToast();

  const { data: { incoming } = {}, loading } = useIncoming(id);

  const [getImport] = useGetImport({
    onError: (err) => {
      console.log(err);
      toast({
        title: "Ocorreu um erro",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const buttonBg = useColorModeValue("governance.700", "governance.700");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <Card w={{ md: "100%", lg: "50%" }}>
        <CardHeader mb="40px">
          <Flex direction="row" w="100%" justify="space-between">
            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="3px">
              Entrada
            </Text>
            <Flex>
              <NavLink to={`/sistema/financeiro/entradas/${id}/editar`}>
                <Button
                  backgroundColor={buttonBg}
                  color="white"
                  _hover={{ bg: "governance.800" }}
                  mr="12px"
                  padding="0 24px"
                >
                  Editar
                </Button>
              </NavLink>
              <NavLink to="/sistema/financeiro/entradas">
                <Button>Voltar</Button>
              </NavLink>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <VStack spacing="24px">
              <FormControl>
                <FormLabel color="gray.600" fontSize="xs">
                  Produto
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!loading}>
                  <Flex align="center">
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mr="12px"
                    >
                      {incoming?.product?.name || null}
                    </Text>
                  </Flex>
                </Skeleton>
              </FormControl>
              <Grid templateColumns="repeat(2, 1fr)" w="100%">
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Valor
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!loading}>
                    <Flex align="center">
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mr="12px"
                      >
                        {incoming?.value
                          ? formatCurrency(incoming?.value)
                          : null}
                      </Text>
                    </Flex>
                  </Skeleton>
                </FormControl>

                {incoming?.competence && (
                  <FormControl>
                    <FormLabel color="gray.600" fontSize="xs">
                      Competência
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!loading}>
                      <Flex align="center">
                        <Text
                          color={textColor}
                          fontSize="lg"
                          fontWeight="bold"
                          mr="12px"
                        >
                          {incoming?.competence
                            ? dateFormat(incoming.competence)
                                .split("/")
                                .slice(1)
                                .join("/")
                            : null}
                        </Text>
                      </Flex>
                    </Skeleton>
                  </FormControl>
                )}
                {incoming?.release_date && (
                  <FormControl>
                    <FormLabel color="gray.600" fontSize="xs">
                      Data do lançamento
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!loading}>
                      <Flex align="center">
                        <Text
                          color={textColor}
                          fontSize="lg"
                          fontWeight="bold"
                          mr="12px"
                        >
                          {incoming?.release_date
                            ? dateFormat(incoming.release_date)
                            : null}
                        </Text>
                      </Flex>
                    </Skeleton>
                  </FormControl>
                )}

                {incoming?.payed_at && (
                  <FormControl>
                    <FormLabel color="gray.600" fontSize="xs">
                      Data de pagamento
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!loading}>
                      <Flex align="center">
                        <Text
                          color={textColor}
                          fontSize="lg"
                          fontWeight="bold"
                          mr="12px"
                        >
                          {incoming?.payed_at
                            ? dateFormat(incoming.payed_at)
                            : null}
                        </Text>
                      </Flex>
                    </Skeleton>
                  </FormControl>
                )}

                {incoming?.type && (
                  <FormControl>
                    <FormLabel color="gray.600" fontSize="xs">
                      Tipo
                    </FormLabel>
                    <Flex align="center">
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mr="12px"
                      >
                        {incoming?.type}
                      </Text>
                    </Flex>
                  </FormControl>
                )}

                {incoming?.status && (
                  <FormControl>
                    <FormLabel color="gray.600" fontSize="xs">
                      Status
                    </FormLabel>
                    <Flex align="center">
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mr="12px"
                      >
                        {incoming?.status}
                      </Text>
                    </Flex>
                  </FormControl>
                )}
              </Grid>

              {incoming?.imports && (
                <FormControl>
                  <FormLabel color="gray.600" fontSize="xs">
                    Anexos
                  </FormLabel>
                  <Flex gap="12px" direction="column">
                    {incoming?.imports?.map((document) => (
                      <Flex align="center">
                        <Text
                          color={textColor}
                          fontSize="lg"
                          fontWeight="bold"
                          mr="12px"
                        >
                          {document.filename}
                        </Text>
                        <Icon
                          onClick={() => {
                            console.log(document?.id);
                            getImport({ variables: { id: document?.id } });
                          }}
                          as={FaDownload}
                          color="gray.600"
                          minW="20px"
                          minH="20px"
                          margin=".5em"
                          _hover={{ cursor: "pointer" }}
                        />
                      </Flex>
                    ))}
                  </Flex>
                </FormControl>
              )}
            </VStack>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default ViewIncoming;
