import { gql } from '@apollo/client';

export const GET_AREAS =  gql`
    query getAreas {
        areas {
            id
            name
        }
    }
`

export const GET_AREAS_PAGINATE = gql`
    query getAreas($first: Int! = 30, $page: Int! = 1) {
        paginateAreas(first: $first, page: $page) {
            paginatorInfo {
                count
                currentPage
                firstItem
                perPage
                lastItem
                total
            }
            data {
                id
                name
            }
        }
    }
`

export const GET_AREA = gql`
    query getArea($id: ID!) {
        area(id: $id) {
            id
            name
            collaborators {
                id
                name
            }
        }
    }
`

export const CREATE_AREA = gql`
    mutation createArea($input: CreateArea) {
        createArea(input: $input) {
            id
            name
        }
    }
`

export const UPDATE_AREA = gql`
    mutation updateArea($input: UpdateArea) {
        updateArea(input: $input) {
            id
        }
    }
`

export const DELETE_AREA = gql`
    mutation deleteArea($id: ID!) {
        deleteArea(id: $id) {
            id
        }
    }
`
