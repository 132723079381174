const { WalletIcon, SettingsIcon } = require("components/Icons/Icons");

const menuItems = [
  {
    name: "Administração",
    path: "/administracao",
    icon: <SettingsIcon color="inherit" />,
    collapse: true,
    roles: [],
    items: [
      {
        name: "Usuários",
        path: "/administracao/usuarios",
        roles: [],
      },
      {
        name: "Colaboradores",
        path: "/administracao/colaboradores",
        roles: [],
      },
      {
        name: "Áreas",
        path: "/administracao/areas",
        roles: [],
      },
      {
        name: "Clientes",
        path: "/administracao/clientes",
        roles: [],
      },
    ],
  },
  {
    name: "financeiro",
    path: "/financeiro",
    icon: <WalletIcon color="inherit" />,
    collapse: true,
    roles: [],
    items: [
      {
        name: "Entradas",
        path: "/financeiro/entradas",
        roles: [],
      },
      {
        name: "Saídas",
        path: "/financeiro/saidas",
        roles: [],
      },
    ],
  },
];

export default menuItems;
