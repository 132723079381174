import React from "react";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

const ClientForm = ({ defaultValues = {} }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputHover = useColorModeValue("governance.800", "governance.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.name}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            Nome
          </FormLabel>
          <Input
            {...register("name")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.name}
          />
          {errors.name && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }} gap="24px">
        <FormControl isInvalid={errors.cnpj}>
          <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
            CNPJ
          </FormLabel>
          <Input
            {...register("cnpj")}
            focusBorderColor={inputHover}
            borderRadius="8px"
            fontSize="md"
            defaultValue={defaultValues?.cnpj}
          />
          {errors.cnpj && (
            <FormErrorMessage>{errors.cnpj.message}</FormErrorMessage>
          )}
        </FormControl>
      </Grid>
    </>
  );
};

export default ClientForm;
