import { useState } from "react";

import AsyncTable from "components/Tables/AsyncTable";

import { useProducts } from "hooks/products";
import { usePaginateProducts } from "hooks/products";

import DeleteProduct from "./DeleteProduct";
import CreateProduct from "./CreateProduct";
import EditProduct from "./EditProduct";
import ViewProduct from "./ViewProduct";

const columns = [
  {
    Header: "Nome",
    accessor: "name",
  },
];

const ProductsTable = ({ clientId, createModal, setCreateModal }) => {
  const { loading, rows = [{}], total, perPage, currentPage } = useProducts();

  const [paginateProducts, { loading: reloading }] = usePaginateProducts();

  const [viewModal, setViewModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [product, setProduct] = useState({});

  const fetchData = ({ pageSize, pageIndex, search }) => {
    if (pageSize != perPage) pageIndex = 1;
    paginateProducts({
      variables: {
        first: pageSize || 5,
        page: pageIndex || 1,
        client_id: clientId,
      },
    });
  };

  const handleView = (id) => {
    setProduct(id);
    setViewModal(true);
  };

  const handleDelete = (id) => {
    setProduct(id);
    setDeleteModal(true);
  };

  const handleEdit = (id) => {
    setProduct(id);
    setUpdateModal(true);
  };

  return (
    <>
      <ViewProduct
        isOpen={viewModal}
        closeModal={() => setViewModal(false)}
        id={product}
      />
      <CreateProduct
        isOpen={createModal}
        closeModal={() => setCreateModal(false)}
      />
      <EditProduct
        isOpen={updateModal}
        closeModal={() => setUpdateModal(false)}
        id={product}
      />
      <DeleteProduct
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(false)}
        id={product}
      />
      <AsyncTable
        isLoading={loading || reloading}
        fetchData={fetchData}
        data={!loading && !reloading ? rows : [{}, {}, {}, {}]}
        columns={columns}
        total={total}
        asyncPage={currentPage}
        baseRoute="produtos"
        size={5}
        viewAction={(id) => handleView(id)}
        removeAction={(id) => handleDelete(id)}
        editAction={(id) => handleEdit(id)}
      />
    </>
  );
};

export default ProductsTable;
