import { useEffect, useState } from "react";
import { Flex, FormControl, FormLabel, Select, Text } from "@chakra-ui/react";

import { getCurrCompetence } from "functions/date";
import { getCompetencesInRange } from "functions/date";
import { useAllProducts } from "hooks/products";

export default function IncomingsFilter({ filters, setFilter }) {
  const {
    data: { products } = {},
    loading: loadingProducts,
  } = useAllProducts();
  const [competences, setCompetences] = useState(null);
  const currCompetence = getCurrCompetence();

  const handleFilter = (e, key) => {
    if (e.target.value == "" || e.target.value == null)
      return setFilter({ ...filters, [key]: null });
    return setFilter({ ...filters, [key]: e.target.value });
  };

  useEffect(() => {
    setCompetences(getCompetencesInRange());
  }, []);

  return (
    <Flex maxW="50%" gap="24px" ml="1em">
      <FormControl>
        <FormLabel ml="12px" color="gray.600" fontWeight="bold" fontSize="xs">
          Filtar por Competência
        </FormLabel>
        {competences && (
          <Select
            borderRadius="25px"
            fontSize="md"
            placeholder="Selecione..."
            onChange={(e) => {
              handleFilter(e, "competence");
            }}
          >
            {competences?.map((competence) => (
              <option value={competence}>{competence}</option>
            ))}
          </Select>
        )}
      </FormControl>

      <FormControl>
        <FormLabel ml="12px" color="gray.600" fontWeight="bold" fontSize="xs">
          Filtar por Produto
        </FormLabel>
        {!loadingProducts && (
          <Select
            borderRadius="25px"
            fontSize="md"
            placeholder="Selecione..."
            onChange={(e) => {
              handleFilter(e, "product");
            }}
          >
            {products?.map((product) => (
              <option value={product.id}>{product.name}</option>
            ))}
          </Select>
        )}
      </FormControl>
    </Flex>
  );
}
